import React, { useEffect, useState } from 'react';
import gatewayApiInstance from '../../services/gatewayAPI/gatewayApiInstance/gatewayApiInstance';
import { stockIntegrationInterface } from '../integrations/viewEditIntegrationsTypes/stock/stockContext';
import { shipmentIntegrationInterface } from '../integrations/viewEditIntegrationsTypes/shipment/shipmentContext';
import { orderIntegrationInterface } from '../integrations/viewEditIntegrationsTypes/order/orderContext';
import { configMappingInterface } from '../integrations/createIntegraton/createIntegrationContext';

// interfaces
export interface suppliersOverviewContextInterface {
    suppliers: suppliersIntegrationsInterface[];
    setSuppliers: (state: never) => void;
}

export interface suppliersIntegrationsInterface {
    id: number;
    supplierName: string;
    integrationsNames: string[];
    integrationsObjects: integrationInterface[];
}

export interface integrationInterface {
    id: string;
    eventName: string;
    plugin: string;
    platform?: string;
    schedule: string;
    config: any | configMappingInterface | stockIntegrationInterface | shipmentIntegrationInterface | orderIntegrationInterface;
    supplier: null | {
        id?: string;
        name?: string;
        createdAt: string;
        updatedAt: string;
    };
    marketplace: {
        id?: string;
        name?: string;
        createdAt: string;
        updatedAt: string;
    };
    isActive?: boolean;
    createdAt: string;
    updatedAt: string;
    integrationName?: string;
}

// initialize the context
export const SuppliersOverviewContext = React.createContext<suppliersOverviewContextInterface>({} as suppliersOverviewContextInterface);

export const SuppliersOverviewProvider: React.FC = ({ children }) => {
    const [suppliers, setSuppliers] = useState<suppliersIntegrationsInterface[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [fetchingStatus, setFetchingStatus] = useState('Fetching suppliers integrations!');

    const fetchSuppliers = async (): Promise<void> => {
        const response = await gatewayApiInstance.get('/suppliers-integrations');
        if (response.status === 200) {
            setSuppliers(response.data);
        } else {
            console.log(response);
        }
    };

    // execute these functions on page mount
    useEffect(() => {
        void (async () => {
            await fetchSuppliers();
            setFetchingStatus('Fetching suppliers integrations done!');
        })();
    }, []);

    return (
        <SuppliersOverviewContext.Provider
            value={{
                suppliers,
                setSuppliers,
            }}
        >
            {children}
        </SuppliersOverviewContext.Provider>
    );
};
