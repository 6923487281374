import React, { useContext, useEffect, useRef, useState } from 'react';
import gatewayApiInstance from '../../../services/gatewayAPI/gatewayApiInstance/gatewayApiInstance';
import { useNavigate } from 'react-router-dom';
import { SupplierContext } from '../../supplier/supplier/supplierContext';

// interfaces
export interface deleteIntegrationContext {
    deleteIntegration: (integration: Record<any, any>) => void;
    showDeleteSuccessModal: boolean;
    setShowDeleteSuccessModal: (state: boolean) => void;
}

export const DeleteIntegrationContext = React.createContext<deleteIntegrationContext>({} as deleteIntegrationContext);

export const DeleteIntegrationProvider: React.FC = ({ children }) => {
    const { getSupplier } = useContext(SupplierContext);
    const navigate = useNavigate();
    const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
    const supplierName = localStorage.getItem('supplierName') as string;
    const supplierNumber = localStorage.getItem('supplierNumber' as string);

    const deleteIntegration = async (integration: Record<any, any>) => {
        const response = await gatewayApiInstance.delete(`/suppliers-integrations/${integration.id}`);
        if (response.status === 200) {
            setShowDeleteSuccessModal(true);
            navigate(`/supplier/${supplierNumber}`);

            reloadIntegrations();
        } else {
            console.log(response);
        }
    };

    const reloadIntegrations = () => {
        void getSupplier(supplierName);
    };

    return (
        <DeleteIntegrationContext.Provider
            value={{
                deleteIntegration,
                showDeleteSuccessModal,
                setShowDeleteSuccessModal,
            }}
        >
            {children}
        </DeleteIntegrationContext.Provider>
    );
};
