import React, { useContext, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { Comparator, selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search, ToolkitContextType } from 'react-bootstrap-table2-toolkit';
import { useNavigate } from 'react-router-dom';
import { IntegrationsOverviewContext } from '../../integrationsOverview/integrationsOverviewContext';
import { IntegrationServiceContext } from '../../integrations/integrationService/integrationServiceContext';
import { CreateIntegration } from '../../uiComponents/buttons/createIntegration';
import { headerClass, headerContent, integrationNameHeader, subHeader, suppliersOverviewWrapper } from './../../integrations/integrationForm.module.scss';
import { createIntegrationButton, supplierIntegrationsOverviewWrapper } from './../../integrationsOverview/integrationsOverview.module.scss';
import { searchField, onHover } from './../../ellaSuppliersOverview/suppliersOverview.module.scss';
import { SupplierContext } from '../supplier/supplierContext';

const { SearchBar, ClearSearchButton } = Search;

export const SupplierIntegrationsOverview: React.FC = () => {
    const navigate = useNavigate();
    const { integrations, fetchIntegrations } = useContext(IntegrationsOverviewContext);
    const { getIntegration } = useContext(IntegrationServiceContext);
    const supplierName: string = localStorage.getItem('supplierName') as string;
    const supplierNumber: string = sessionStorage.getItem('supplierNumber') as string;

    useEffect(() => {
        fetchIntegrations();
    }, []);

    console.log(integrations);

    const selectOptions = {
        'FTP stock NL': 'FTP stock NL',
        'FTP stock DE': 'FTP stock DE',
        'FTP stock FR': 'FTP stock FR',
        'FTP shipment NL': 'FTP shipment NL',
        'FTP shipment DE': 'FTP shipment DE',
        'FTP shipment FR': 'FTP shipment FR',
        'FTP order NL': 'FTP order NL',
        'FTP order DE': 'FTP order DE',
        'FTP order FR': 'FTP order FR',
    };

    const supplierIntegrations = integrations;

    const cleanUpFormatter = (row: any) => {
        // @ts-ignore
        const res = row.replace('undefined', '');
        return <div className={onHover}>{res}</div>;
    };

    const dateTimeFormatter = (row: any) => {
        const dateTime = new Date(row);
        const res = dateTime.toUTCString();
        return <div>{res}</div>;
    };

    const booleanToReadable = (row: any) => {
        if (row === true) {
            return <div>Yes</div>;
        } else {
            return <div>No</div>;
        }
    };

    const marketPlaceFormatter = (row: any) => {
        switch (row) {
            case 'nl_NL':
                return <div>NL</div>;
            case 'de_DE':
                return <div>DE</div>;
            case 'fr_FR':
                return <div>FR</div>;
            default:
                return <div />;
        }
    };

    const onHoverClick = (row: any) => {
        return <div className={`${onHover}`}>{row}</div>;
    };

    // table headers
    const columns = [
        {
            // should be set to supplier.name
            dataField: 'plugin',
            text: 'Supplier',
            sort: true,
            formatter: onHoverClick,
            events: {
                onClick: () => {
                    navigate(`/supplier/${supplierNumber}`);
                },
            },
        },
        {
            dataField: 'integrationName',
            text: 'Integration',
            sort: true,
            formatter: cleanUpFormatter,
            filter: selectFilter({
                options: selectOptions,
                comparator: Comparator.LIKE,
            }),
            headerClasses: `${integrationNameHeader}`,
            events: {
                onClick: (e: any, cell: any, row: any, rowIndex: any) => {
                    const integrationType = rowIndex.eventName.split('.');
                    // should be set to supplier.name
                    localStorage.setItem('supplierName', rowIndex.plugin);
                    getIntegration(rowIndex.plugin, integrationType);
                    navigate(`/supplier/${supplierNumber}/${integrationType[1]}`);
                },
            },
        },
        {
            dataField: 'marketplace.name',
            text: 'Marketplace',
            sort: true,
            formatter: marketPlaceFormatter,
        },
        {
            dataField: 'isActive',
            text: 'Active',
            sort: true,
            align: 'center',
            formatter: booleanToReadable,
            style: (row: boolean) => {
                if (row) {
                    return {
                        backgroundColor: '#C6FFC1',
                    };
                } else {
                    return {
                        backgroundColor: '',
                    };
                }
            },
        },
        {
            dataField: 'updatedAt',
            text: 'Last update',
            sort: true,
            headerStyle: () => {
                return {
                    paddingLeft: '1em',
                };
            },
            formatter: dateTimeFormatter,
            style: () => {
                return {
                    paddingLeft: '1em',
                };
            },
        },
    ];

    return (
        <>
            <ToolkitProvider keyField={'id'} data={supplierIntegrations} columns={columns} search={{ defaultSearch: supplierName }}>
                {(props: ToolkitContextType) => (
                    <>
                        <div className={supplierIntegrationsOverviewWrapper}>
                            <h4>Integrations overview</h4>

                            <div className={createIntegrationButton}>{<CreateIntegration fromSupplier={true} />}</div>
                        </div>
                        <hr />
                        <div className={suppliersOverviewWrapper}>
                            <BootstrapTable
                                {...props.baseProps}
                                filter={filterFactory()}
                                bootstrap4={true}
                                sort={{ dataField: 'id', order: 'asc' }}
                                striped
                                hover
                                bordered={false}
                                noDataIndication="No integrations found!"
                                headerClasses={headerClass}
                            />
                        </div>
                    </>
                )}
            </ToolkitProvider>
        </>
    );
};
