import React, { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { StockContext } from '../../integrations/viewEditIntegrationsTypes/stock/stockContext';
import { buttons } from './modal.module.scss';
import { DeleteIntegrationContext } from '../../integrations/deleteIntegration/deleteIntegrationContext';
import { IntegrationServiceContext } from '../../integrations/integrationService/integrationServiceContext';

interface Props {
    show: boolean;
    setShow: (state: any) => void;
}

const AreYouSureModal: React.FC<Props> = ({ show, setShow }) => {
    const { integration } = useContext(IntegrationServiceContext);
    const { deleteIntegration } = useContext(DeleteIntegrationContext);

    const handleCancelDelete = () => setShow(false);
    const handleSureDelete = () => {
        setShow(false);
        deleteIntegration(integration);
    };

    return (
        <>
            <Modal show={show} onHide={handleCancelDelete} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>Are you sure ?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this {integration.config?.platform} {integration.eventName} integration, with {integration.plugin}?
                </Modal.Body>
                <Modal.Footer className={buttons}>
                    <Button onClick={handleCancelDelete}>No, I&apos;m not sure</Button>
                    <Button onClick={handleSureDelete}>Yes, I&apos;m sure</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AreYouSureModal;
