import React, { useContext, useEffect, useState } from 'react';
import BootstrapTable, { PaginationOptions } from 'react-bootstrap-table-next';
import filterFactory, { Comparator, selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search, SearchBarProps, ToolkitContextType } from 'react-bootstrap-table2-toolkit';
import { useNavigate } from 'react-router-dom';
import { title, headerClass, createIntegrationButton, headerContent, suppliersOverviewWrapper, integrationNameHeader } from './integrationsOverview.module.scss';
import { header, searchField, onHover } from './../ellaSuppliersOverview/suppliersOverview.module.scss';
import { CreateIntegration } from '../uiComponents/buttons/createIntegration';
import { IntegrationsOverviewContext } from './integrationsOverviewContext';
import { IntegrationServiceContext } from '../integrations/integrationService/integrationServiceContext';
import paginationFactory from 'react-bootstrap-table2-paginator';

const { SearchBar, ClearSearchButton } = Search;

export const IntegrationsOverview: React.FC = () => {
    const navigate = useNavigate();
    const { integrations } = useContext(IntegrationsOverviewContext);
    const { getIntegration } = useContext(IntegrationServiceContext);
    const supplierName: string = localStorage.getItem('supplierName') as string;
    const supplierNumber: string = localStorage.getItem('supplierNumber') as string;

    const selectOptions = {
        'FTP stock NL': 'FTP stock NL',
        'FTP stock DE': 'FTP stock DE',
        'FTP stock FR': 'FTP stock FR',
        'FTP shipment NL': 'FTP shipment NL',
        'FTP shipment DE': 'FTP shipment DE',
        'FTP shipment FR': 'FTP shipment FR',
        'FTP order NL': 'FTP order NL',
        'FTP order DE': 'FTP order DE',
        'FTP order FR': 'FTP order FR',
    };

    const supplierIntegrations = integrations;

    const handleClick = () => {
        navigate(`/supplier/${supplierNumber}`);
    };

    const cleanUpFormatter = (row: any) => {
        return <div className={onHover}>{row}</div>;
    };

    const dateTimeFormatter = (row: any) => {
        const dateTime = new Date(row);
        const res = dateTime.toUTCString();
        return <div>{res}</div>;
    };

    const booleanToReadable = (row: any) => {
        if (row === true) {
            return <div>Yes</div>;
        } else {
            return <div>No</div>;
        }
    };

    const marketPlaceFormatter = (row: any) => {
        switch (row) {
            case 'nl_NL':
                return <div>NL</div>;
            case 'de_DE':
                return <div>DE</div>;
            case 'fr_FR':
                return <div>FR</div>;
            default:
                return <div />;
        }
    };

    const onHoverClick = (row: any) => {
        return <div className={`${onHover}`}>{row}</div>;
    };

    // table headers
    const columns = [
        {
            dataField: 'plugin',
            text: 'Supplier',
            sort: true,
            formatter: onHoverClick,
            events: {
                onClick: () => {
                    navigate(`/supplier/${supplierNumber}`);
                },
            },
        },
        {
            dataField: 'integrationName',
            text: 'Integration',
            sort: true,
            formatter: cleanUpFormatter,
            filter: selectFilter({
                options: selectOptions,
                comparator: Comparator.LIKE,
            }),
            headerClasses: `${integrationNameHeader}`,
            events: {
                onClick: (e: any, cell: any, row: any, rowIndex: any) => {
                    const integrationType = rowIndex.eventName.split('.');
                    sessionStorage.setItem('supplierName', rowIndex.supplier.name);
                    getIntegration(rowIndex.supplier.name, integrationType);
                    navigate(`/supplier/${supplierNumber}/${integrationType[1]}`);
                },
            },
        },
        {
            dataField: 'marketplace.name',
            text: 'Marketplace',
            sort: true,
            formatter: marketPlaceFormatter,
        },
        {
            dataField: 'isActive',
            text: 'Active',
            sort: true,
            align: 'center',
            formatter: booleanToReadable,
            style: (row: boolean) => {
                if (row) {
                    return {
                        backgroundColor: '#C6FFC1',
                    };
                } else {
                    return {
                        backgroundColor: '',
                    };
                }
            },
        },
        {
            dataField: 'updatedAt',
            text: 'Last update',
            sort: true,
            headerStyle: () => {
                return {
                    paddingLeft: '1em',
                };
            },
            formatter: dateTimeFormatter,
            style: () => {
                return {
                    paddingLeft: '1em',
                };
            },
        },
    ];

    const options: PaginationOptions = {
        sizePerPage: 10,
        showTotal: true,
        alwaysShowAllBtns: true,
        hidePageListOnlyOnePage: true,
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '25',
                value: 25,
            },
            {
                text: '50',
                value: 50,
            },
            {
                text: '100',
                value: 100,
            },
            {
                text: 'All',
                value: supplierIntegrations.length,
            },
        ],
    };

    return (
        <>
            <ToolkitProvider keyField={'id'} data={supplierIntegrations} columns={columns} search>
                {(props: ToolkitContextType) => (
                    <div>
                        <div className={header}>
                            <div className={title}>
                                <div>
                                    <h2>Integrations overview</h2>
                                </div>
                            </div>

                            <div className={headerContent}>
                                <div className={`${searchField} `}>
                                    <SearchBar {...props.searchProps} />
                                    <ClearSearchButton {...props.searchProps} />
                                </div>
                                <div className={`${createIntegrationButton} `}>
                                    <CreateIntegration fromSupplier={false} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <BootstrapTable
                            {...props.baseProps}
                            filter={filterFactory()}
                            pagination={paginationFactory(options)}
                            bootstrap4
                            sort={{ dataField: 'id', order: 'asc' }}
                            striped
                            hover
                            bordered={false}
                            noDataIndication="No integrations found!"
                            headerClasses={headerClass}
                        />
                    </div>
                )}
            </ToolkitProvider>
        </>
    );
};
