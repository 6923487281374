import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export const BackButton: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <Button size="sm" name="backButton" className="button icon-left" onClick={() => navigate(-1)}>
                Back
            </Button>
        </>
    );
};
