import * as React from 'react';
import { useContext, useEffect } from 'react';
import { integrationFormHeader, integrationFormWrapper, settingsWrapper, subFormTitle } from '../../integrationForm.module.scss';
import { IntegrationControls } from '../../integrationsControls/integrationsControls';
import { useNavigate } from 'react-router-dom';
import { SupplierContext } from '../../../supplier/supplier/supplierContext';
import { IntegrationServiceContext } from '../../integrationService/integrationServiceContext';
import { integrationInterface } from '../../../suppliersOverview/suppliersOverviewContext';
import { renderPlatformSettings } from '../../integrationService/renderService';
import { Error } from '../../../uiComponents/modals/error';
import FTPorder from '../../platformSpecificIntegrations/FTPorder';
import MAGENTO_2order from '../../platformSpecificIntegrations/MAGENTO_2order';
import SupplierHeader from '../../../supplier/supplierHeader/SupplierHeader';
import { divider } from '../../../../pages/supplierPage/supplierPage.module.scss';

const Order: React.FC = () => {
    const { integration, viewMode, setViewMode, getIntegration } = useContext(IntegrationServiceContext);
    const supplierName = localStorage.getItem('supplierName' as string);
    let order: Record<any, any> = {} as integrationInterface;

    // update stock state
    useEffect(() => {
        if (!order) {
            getIntegration(`${supplierName}`, 'fetch.stock');
        }
    }, []);

    return (
        <>
            <div className="integrationFormWrapper">
                <SupplierHeader />
                <div className={divider}>
                    <hr />
                </div>

                <div className={settingsWrapper}>
                    <FTPorder viewMode={viewMode} />
                    <hr />
                </div>
            </div>

            <IntegrationControls viewMode={viewMode} setViewMode={setViewMode} integrationName={'FTPorder'} />
        </>
    );
};

export default Order;
