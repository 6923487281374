import React, { useContext, useEffect, useState } from 'react';
import {
    dateInfo,
    viewLabels,
    delimiter,
    labels,
    dateStringClass,
    integrationForm,
    generalSettingsClass,
    isActiveClass,
    mappingForm,
    integrationFormSubHeader,
    mappingWrapper,
    subFormTitle,
} from '../integrationForm.module.scss';
import { errClass } from './../platformSpecificIntegrations/styles/formStyles.module.scss';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { IntegrationServiceContext } from '../integrationService/integrationServiceContext';
import { configMappingInterface, CreateIntegrationContext } from '../createIntegraton/createIntegrationContext';
import { ftpStockFieldErrorsInitialState } from '../integrationService/integrationServiceInterfaces';

interface Props {
    viewMode: boolean;
}

const FTPstock: React.FC<Props> = ({ viewMode }: Props): JSX.Element => {
    const { integration, setIntegration, computeDelimiter, formatISOtoReadable, ftpStockFieldErrors, config, setConfig, setFtpStockFieldErrors } = useContext(IntegrationServiceContext);
    const [fields, setFields] = useState<string[]>([]);

    const stock = integration;
    console.log(stock);
    localStorage.setItem('INTEGRATION', JSON.stringify(stock));

    const [mapping, setMapping] = useState<configMappingInterface>({} as configMappingInterface);

    const handleChangeToMapping = (evt: any, value: string) => {
        // setMapping({ ...mapping[`${value}`], [value]: evt.target.value });
        setConfig({ ...config.mapping, mapping });
        handleChangeToIntegrationObject();
    };

    const handleChangeToConfig = (evt: any, value: string) => {
        setConfig({ ...config, [value]: evt.target.value });
        handleChangeToIntegrationObject();
    };

    const handleChangeToIntegrationObject = () => {
        setIntegration({ ...stock, config });
    };

    const handleIsActiveState = () => {
        if (stock.isActive) {
            setIntegration({ ...stock, isActive: false });
        } else {
            setIntegration({ ...stock, isActive: true });
        }
    };

    useEffect(() => {
        setFtpStockFieldErrors({ ...ftpStockFieldErrorsInitialState });
    }, []);

    return (
        <>
            <div>
                <div className={`${subFormTitle}`}>
                    <h4>{stock.integrationName} settings</h4>
                </div>
                <hr />

                <div className={dateInfo}>
                    <div>
                        Last update at:
                        <div className={dateStringClass}>{formatISOtoReadable(stock.updatedAt)}</div>
                    </div>
                    <div>
                        Created at:
                        <div className={dateStringClass}> {formatISOtoReadable(stock.createdAt)}</div>
                    </div>
                </div>

                <Form className={integrationForm}>
                    <div className={generalSettingsClass}>
                        {/* resource & delimiter */}
                        <Row>
                            <Form.Group as={Col} className="mb-2" controlId="resourceUrl" sm={9}>
                                <Form.Label column sm={2} className={labels}>
                                    Url
                                </Form.Label>
                                <Col>
                                    {viewMode ? (
                                        <p className={viewLabels}>{stock.config?.resource}</p>
                                    ) : (
                                        [
                                            ftpStockFieldErrors.resourceErr ? (
                                                <Form.Control
                                                    className={errClass}
                                                    defaultValue={stock.config?.resource}
                                                    onChange={(e) => {
                                                        handleChangeToConfig(e, 'resource');
                                                    }}
                                                    type="text"
                                                    placeholder="sftp://username:password@host/StockInfo/VoorraadLijst.csv"
                                                />
                                            ) : (
                                                <Form.Control
                                                    defaultValue={stock.config?.resource}
                                                    onChange={(e) => {
                                                        handleChangeToConfig(e, 'resource');
                                                    }}
                                                    type="text"
                                                    placeholder="sftp://username:password@host/StockInfo/VoorraadLijst.csv"
                                                />
                                            ),
                                        ]
                                    )}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Col} className="mb-4" controlId="resourceUrl" sm={2}>
                                <Form.Label column sm={2} className={labels}>
                                    Delimiter
                                </Form.Label>
                                <Col>
                                    {viewMode ? (
                                        <p className={`${viewLabels} ${delimiter}`}>{computeDelimiter(stock)}</p>
                                    ) : (
                                        [
                                            ftpStockFieldErrors.delimiterErr ? (
                                                <Form.Control
                                                    className={errClass}
                                                    defaultValue={stock.config?.delimiter}
                                                    onChange={(e) => {
                                                        handleChangeToConfig(e, 'delimiter');
                                                    }}
                                                    placeholder="; or ,"
                                                />
                                            ) : (
                                                <Form.Control
                                                    defaultValue={stock.config?.delimiter}
                                                    onChange={(e) => {
                                                        handleChangeToConfig(e, 'delimiter');
                                                    }}
                                                    placeholder="; or ,"
                                                />
                                            ),
                                        ]
                                    )}
                                </Col>
                            </Form.Group>
                            {/* active */}
                            <Form.Group as={Col} className="mb-4" controlId="resourceUrl" sm={2}>
                                <Form.Label column sm={2} className={labels}>
                                    Active
                                </Form.Label>
                                <Col>
                                    {viewMode ? (
                                        <p style={{ backgroundColor: stock?.isActive ? '#C6FFC1' : '' }} className={`${viewLabels} ${isActiveClass}`}>
                                            {stock?.isActive ? 'Yes' : 'No'}
                                        </p>
                                    ) : (
                                        [
                                            stock.isActive ? (
                                                <Form.Check defaultChecked type="checkbox" label="is active" onClick={handleIsActiveState} />
                                            ) : (
                                                <Form.Check type="checkbox" label="is active" onClick={handleIsActiveState} />
                                            ),
                                        ]
                                    )}
                                </Col>
                            </Form.Group>
                        </Row>
                    </div>
                    <hr />

                    {/*mapping*/}
                    <div className={mappingForm}>
                        <div className={integrationFormSubHeader}>
                            <h5>Stock mapping</h5>
                        </div>

                        {/* SKU */}
                        <Form.Group as={Row} className={`mb-3`} controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                SKU field
                            </Form.Label>
                            <Col sm={8}>
                                {viewMode ? (
                                    <Col className={`${viewLabels} ${mappingWrapper}`}>{stock.config?.skuField !== '' ? stock.config?.skuField : stock.config?.mapping?.skuField}</Col>
                                ) : (
                                    [
                                        ftpStockFieldErrors.skuFieldErr ? (
                                            <Form.Control
                                                className={errClass}
                                                defaultValue={stock.config?.skuField !== '' ? stock.config?.skuField : stock.config?.mapping?.skuField}
                                                placeholder="SKU_field"
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'skuField');
                                                }}
                                            />
                                        ) : (
                                            <Form.Control
                                                defaultValue={stock.config?.skuField !== '' ? stock.config?.skuField : stock.config?.mapping?.skuField}
                                                placeholder="SKU_field"
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'skuField');
                                                }}
                                            />
                                        ),
                                    ]
                                )}
                            </Col>
                        </Form.Group>

                        {/* stock */}
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                Stock field
                            </Form.Label>
                            <Col sm={8}>
                                {viewMode ? (
                                    <Col className={`${viewLabels} ${mappingWrapper}`}>{stock.config?.stockField !== '' ? stock.config?.stockField : stock.config?.mapping?.stockField}</Col>
                                ) : (
                                    [
                                        ftpStockFieldErrors.stockFieldErr ? (
                                            <Form.Control
                                                className={errClass}
                                                defaultValue={stock.config?.stockField !== '' ? stock.config?.stockField : stock.config?.mapping?.stockField}
                                                placeholder="stock_field"
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'stockField');
                                                }}
                                            />
                                        ) : (
                                            <Form.Control
                                                defaultValue={stock.config?.stockField !== '' ? stock.config?.stockField : stock.config?.mapping?.stockField}
                                                placeholder="stock_field"
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'stockField');
                                                }}
                                            />
                                        ),
                                    ]
                                )}
                            </Col>
                        </Form.Group>

                        {/* price*/}
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                Price field
                            </Form.Label>
                            <Col sm={8}>
                                {viewMode ? (
                                    <Col className={`${viewLabels} ${mappingWrapper}`}>{stock.config?.priceField !== '' ? stock.config?.priceField : stock.config?.mapping?.priceField}</Col>
                                ) : (
                                    <Form.Control
                                        defaultValue={stock.config?.stockField !== '' ? stock.config?.priceField : stock.config?.mapping?.priceField}
                                        placeholder="price_field"
                                        onChange={(e) => {
                                            handleChangeToConfig(e, 'priceField');
                                        }}
                                    />
                                )}
                            </Col>
                        </Form.Group>

                        {/* compareAtPrice*/}
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                Compare at price field
                            </Form.Label>
                            {viewMode ? (
                                <Col className={`${viewLabels} ${mappingWrapper}`}>
                                    {stock.config?.compareAtPriceField !== '' ? stock.config?.compareAtPriceField : stock.config?.mapping?.compareAtPriceField}
                                </Col>
                            ) : (
                                <Col sm={8}>
                                    <Form.Control
                                        defaultValue={stock.config?.compareAtPriceField !== '' ? stock.config?.compareAtPriceField : stock.config?.mapping?.compareAtPriceField}
                                        placeholder="compare_at_price_field"
                                        onChange={(e) => {
                                            handleChangeToConfig(e, 'compareAtPriceField');
                                        }}
                                    />
                                </Col>
                            )}
                        </Form.Group>

                        {/* ean */}
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                EAN field
                            </Form.Label>
                            {viewMode ? (
                                <Col className={`${viewLabels} ${mappingWrapper}`}>{stock.config?.eanField !== '' ? stock.config?.eanField : stock.config?.mapping?.eanField}</Col>
                            ) : (
                                <Col sm={8}>
                                    <Form.Control
                                        defaultValue={stock.config?.eanField !== '' ? stock.config?.eanField : stock.config?.mapping?.eanField}
                                        placeholder="EAN_field"
                                        onChange={(e) => {
                                            handleChangeToConfig(e, 'eanField');
                                        }}
                                    />
                                </Col>
                            )}
                        </Form.Group>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default FTPstock;
