import React, { useContext, useEffect, useState } from 'react';
import {
    topSettings,
    labels,
    integrationForm,
    mappingForm,
    integrationFormSubHeader,
    fieldLabel,
    middleTextFieldWrapper,
    fieldButtons,
    operatorButton,
    subFormTitle,
} from './../../integrationForm.module.scss';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { CreateIntegrationContext } from '../createIntegrationContext';

export interface FTPstockConfigMappingInterface {
    priceField: string;
    skuField: string;
    compareAtPriceField: string;
    stockField: string;
    eanField: string;
}

const FTPstock: React.FC = (): JSX.Element => {
    const { config, setConfig, integrationInput, setIntegrationInput } = useContext(CreateIntegrationContext);
    const [fields, setFields] = useState<string[]>([]);

    const [mapping, setMapping] = useState<FTPstockConfigMappingInterface>({
        priceField: '',
        skuField: '',
        compareAtPriceField: '',
        stockField: '',
        eanField: '',
    } as FTPstockConfigMappingInterface);

    const handleChangeInput = (i: string | number, e: React.ChangeEvent) => {
        const descriptions = [...fields];
        // @ts-ignore
        descriptions[i] = e.target.value;
        setFields(descriptions);
        handleChangeToConfig(descriptions, 'stockFieldAcceptableDescriptions');
    };

    const handleAddFieldClick = (description: string) => {
        setFields([...fields, description]);
    };

    const handleSubtractionFieldClick = (i: number) => {
        const descriptions = [...fields];
        descriptions.splice(i, 1);
        setFields([...descriptions]);
    };

    // handle change to states
    const handleChangeToMapping = (evt: any, value: string) => {
        setMapping({ ...mapping, [value]: evt.target.value });
        setConfig({ ...config, mapping });
        handleChangeToIntegrationObject();
    };

    const handleChangeToConfig = (evt: any, value: string) => {
        setConfig({ ...config, [value]: evt.target.value });
        handleChangeToIntegrationObject();
    };

    const handleChangeToIntegrationObject = () => {
        setIntegrationInput({ ...integrationInput, config });
    };

    return (
        <>
            <div>
                <div className={subFormTitle}>
                    <h4>FTP Stock settings</h4>
                </div>
                <hr />
                <Form className={integrationForm}>
                    {/* resource & delimiter */}
                    <Row className={topSettings}>
                        <Form.Group as={Col} className="mb-2" controlId="resourceUrl" sm={9}>
                            <Form.Label column sm={2} className={labels}>
                                Url
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    onChange={(e) => {
                                        handleChangeToConfig(e, 'resource');
                                    }}
                                    placeholder="sftp://username:password@host/PriceInfo/PrijsLijst.csv"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-4" controlId="delimiter" sm={2}>
                            <Form.Label column sm={2} className={labels}>
                                Delimiter
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    onChange={(e) => {
                                        handleChangeToConfig(e, 'delimiter');
                                    }}
                                    placeholder="; or ,"
                                />
                            </Col>
                        </Form.Group>
                    </Row>

                    {/*stockFieldAcceptableDescriptions*/}
                    <div className="stockFieldAcceptableDescriptionsFields">
                        {fields.map((field: string, i: number) => (
                            <Form.Group key={i} as={Row} className="mb-4" controlId="stockFieldDefaultStockLevel">
                                <div className={middleTextFieldWrapper}>
                                    <Form.Label column sm={4} className={`${labels} ${fieldLabel}`}>
                                        Field
                                    </Form.Label>
                                    <Col sm={2}>
                                        <Form.Control onChange={(e: React.ChangeEvent) => handleChangeInput(i, e)} placeholder="Stock level 3" />
                                    </Col>

                                    <div className={fieldButtons}>
                                        <Button className={operatorButton} onClick={() => handleAddFieldClick(field)}>
                                            +
                                        </Button>
                                        <Button className={operatorButton} onClick={() => handleSubtractionFieldClick(i)}>
                                            -
                                        </Button>
                                    </div>
                                </div>
                            </Form.Group>
                        ))}
                    </div>

                    <hr />

                    {/*mapping*/}
                    <div className={mappingForm}>
                        <div className={integrationFormSubHeader}>
                            <h5>File fields mapping</h5>
                        </div>

                        {/* SKU */}
                        <Form.Group as={Row} className={`mb-3`} controlId="skuField">
                            <Form.Label column sm={4}>
                                SKU field
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control
                                    placeholder="SKU_field"
                                    onChange={(e) => {
                                        handleChangeToConfig(e, 'skuField');
                                    }}
                                />
                            </Col>
                        </Form.Group>

                        {/* stock */}
                        <Form.Group as={Row} className="mb-3" controlId="stockField">
                            <Form.Label column sm={4}>
                                Stock field
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control
                                    placeholder="stock_field"
                                    onChange={(e) => {
                                        handleChangeToConfig(e, 'stockField');
                                    }}
                                />
                            </Col>
                        </Form.Group>

                        {/* price */}
                        <Form.Group as={Row} className={`mb-3`} controlId="priceField">
                            <Form.Label column sm={4}>
                                Price field
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control
                                    placeholder="price_field"
                                    onChange={(e) => {
                                        handleChangeToConfig(e, 'priceField');
                                    }}
                                />
                            </Col>
                        </Form.Group>

                        {/* compareAtPriceField */}
                        <Form.Group as={Row} className="mb-3" controlId="compareAtPriceField">
                            <Form.Label column sm={4}>
                                Compare At Price field
                            </Form.Label>

                            <Col sm={8}>
                                <Form.Control
                                    placeholder="compareAtPrice_field"
                                    onChange={(e) => {
                                        handleChangeToConfig(e, 'compareAtPriceField');
                                    }}
                                />
                            </Col>
                        </Form.Group>

                        {/* ean */}
                        <Form.Group as={Row} className="mb-3" controlId="eanField">
                            <Form.Label column sm={4}>
                                EAN field
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control
                                    placeholder="EAN_field"
                                    onChange={(e) => {
                                        handleChangeToConfig(e, 'eanField');
                                    }}
                                />
                            </Col>
                        </Form.Group>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default FTPstock;
