import React from 'react';
import { createButton, icon } from './button.module.scss';
import plusIcon from './../../../assets/icons/2561371_plus_square_icon.png';
import { useNavigate } from 'react-router-dom';

interface Props {
    fromSupplier: boolean;
}

export const CreateIntegration: React.FC<Props> = ({ fromSupplier }) => {
    const supplierNumber: string = localStorage.getItem('supplierNumber') as string;
    const navigate = useNavigate();

    console.log(fromSupplier);

    console.log(supplierNumber);

    const handleClick = () => {
        if (fromSupplier) {
            navigate(`/supplier/${supplierNumber}/create-integration`);
        } else {
            navigate(`/supplier/integrations/create-integration`);
        }
    };

    return (
        <>
            <div className={createButton} onClick={handleClick}>
                <div>
                    Create integration
                    <img className={icon} src={plusIcon} alt={plusIcon} />
                </div>
            </div>
        </>
    );
};
