import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import 'frontend-core/src/styles/custom.scss';
import { Contexts } from './services/internal/contexts';
import { BrowserRouter, BrowserRouter as Router } from 'react-router-dom';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Contexts>
                <App />
            </Contexts>
        </BrowserRouter>
    </React.StrictMode>,

    document.getElementById('root')
);
