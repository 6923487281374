// ftp stock error
export interface ftpStockFieldErrorsInterface {
    resourceErr: boolean;
    delimiterErr: boolean;
    skuFieldErr: boolean;
    stockFieldErr: boolean;
}
export const ftpStockFieldErrorsInitialState = {
    resourceErr: false,
    delimiterErr: false,
    stockFieldErr: false,
    skuFieldErr: false,
};

// ftp shipment error
export interface ftpShipmentFieldErrorsInterface {
    resourceErr: boolean;
    archiveResourceErr: boolean;
    delimiterErr: boolean;
    identifierTypeErr: boolean;
    trackTraceNumberFieldErr: boolean;
    carrierFieldErr: boolean;
    identifierFieldErr: boolean;
    skuFieldErr: boolean;
    quantityFieldErr: boolean;
}
export const ftpShipmentFieldErrorsInitialState = {
    resourceErr: false,
    archiveResourceErr: false,
    delimiterErr: false,
    identifierTypeErr: false,
    trackTraceNumberFieldErr: false,
    carrierFieldErr: false,
    identifierFieldErr: false,
    skuFieldErr: false,
    quantityFieldErr: false,
};

// ftp order error
export interface ftpOrderFieldErrorsInterface {
    resourceErr: boolean;
    headerFieldErr: boolean;
    lineFieldErr: boolean;
    footerFieldErr: boolean;
}
export const ftpOrderFieldErrorsInitialState = {
    resourceErr: false,
    headerFieldErr: false,
    lineFieldErr: false,
    footerFieldErr: false,
};
