import React from 'react';
import { CreateIntegration } from '../../../components/integrations/createIntegraton/createIntegration';
import { divider, headerWrapper } from '../../supplierPage/supplierPage.module.scss';
import SupplierHeader from '../../../components/supplier/supplierHeader/SupplierHeader';

interface Props {
    showSupplierHeader: boolean;
}

export const CreateIntegrationPage: React.FC<Props> = ({ showSupplierHeader }) => {
    return (
        <>
            {showSupplierHeader ? (
                <>
                    <div className={headerWrapper}>
                        <SupplierHeader />
                    </div>
                    <div className={divider}>
                        <hr />
                    </div>
                </>
            ) : (
                ''
            )}
            <CreateIntegration />
        </>
    );
};
