import React from 'react';

import { GatewayProvider } from 'frontend-core/src/context/gateway-context';
import { SuppliersOverviewProvider } from '../../components/suppliersOverview/suppliersOverviewContext';
import { EllaSuppliersOverviewProvider } from '../../components/ellaSuppliersOverview/ellasuppliersOverviewContext';
import { SupplierProvider } from '../../components/supplier/supplier/supplierContext';
import { StockProvider } from '../../components/integrations/viewEditIntegrationsTypes/stock/stockContext';
import { DeleteIntegrationProvider } from '../../components/integrations/deleteIntegration/deleteIntegrationContext';
import { CreateIntegrationProvider } from '../../components/integrations/createIntegraton/createIntegrationContext';
import { IntegrationServiceProvider } from '../../components/integrations/integrationService/integrationServiceContext';
import { ShipmentProvider } from '../../components/integrations/viewEditIntegrationsTypes/shipment/shipmentContext';
import { OrderProvider } from '../../components/integrations/viewEditIntegrationsTypes/order/orderContext';
import Header from '../../pages/layout/header/header';
import { IntegrationsOverviewProvider } from '../../components/integrationsOverview/integrationsOverviewContext';
import { FormValidationServiceProvider } from '../../components/integrations/integrationsControls/formValidationServiceContext';

export const Contexts: React.FC = ({ children }) => {
    return (
        <>
            <GatewayProvider>
                <EllaSuppliersOverviewProvider>
                    <SuppliersOverviewProvider>
                        <IntegrationsOverviewProvider>
                            <SupplierProvider>
                                <CreateIntegrationProvider>
                                    <DeleteIntegrationProvider>
                                        <IntegrationServiceProvider>
                                            <FormValidationServiceProvider>
                                                <StockProvider>
                                                    <ShipmentProvider>
                                                        <OrderProvider>
                                                            <Header />
                                                            {children}
                                                        </OrderProvider>
                                                    </ShipmentProvider>
                                                </StockProvider>
                                            </FormValidationServiceProvider>
                                        </IntegrationServiceProvider>
                                    </DeleteIntegrationProvider>
                                </CreateIntegrationProvider>
                            </SupplierProvider>
                        </IntegrationsOverviewProvider>
                    </SuppliersOverviewProvider>
                </EllaSuppliersOverviewProvider>
            </GatewayProvider>
        </>
    );
};
