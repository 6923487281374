import React, { useEffect, useState } from 'react';
import gatewayApiInstance from '../../services/gatewayAPI/gatewayApiInstance/gatewayApiInstance';

// interfaces
export interface ellaSuppliersOverviewInterface {
    suppliers: suppliersInterface[];
    setSuppliers: (state: never) => void;
    fetchSuppliers: () => void;
}

export interface suppliersInterface {
    id: number;
    number: number;
    name: string;
    skuPrefix: string;
}

// initialize the context
export const EllaSuppliersOverviewContext = React.createContext<ellaSuppliersOverviewInterface>({} as ellaSuppliersOverviewInterface);

export const EllaSuppliersOverviewProvider: React.FC = ({ children }) => {
    const [suppliers, setSuppliers] = useState<suppliersInterface[]>([]);

    const fetchSuppliers = async (): Promise<void> => {
        const response = await gatewayApiInstance.get('/suppliers/ella');
        if (response.status === 200) {
            let sup1 = {
                id: 980,
                number: 4940,
                name: 'CozyHouse',
                skuPrefix: 'COH-',
            };

            let sup2 = {
                id: 879,
                number: 3450,
                name: 'TestSecondSupplier',
                skuPrefix: 'TSS-',
            };

            const sups = response.data.suppliers.concat(sup1);
            const sups2 = sups.concat(sup2);

            setSuppliers(sups2);
        } else {
            console.log(response);
        }
    };

    // execute these functions on page mount
    useEffect(() => {
        void (async () => {
            await fetchSuppliers();
        })();
    }, []);

    return (
        <EllaSuppliersOverviewContext.Provider
            value={{
                suppliers,
                setSuppliers,
                fetchSuppliers,
            }}
        >
            {children}
        </EllaSuppliersOverviewContext.Provider>
    );
};
