import React, { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { cancelButton, oKButton } from './modal.module.scss';
import { CreateIntegrationContext } from '../../integrations/createIntegraton/createIntegrationContext';
import { useNavigate } from 'react-router-dom';
import { SupplierContext } from '../../supplier/supplier/supplierContext';

interface Props {
    show: boolean;
    setShow: (state: any) => void;
}

const CreatedIntegration: React.FC<Props> = ({ show, setShow }) => {
    const navigate = useNavigate();
    const { createdIntegrationObject } = useContext(CreateIntegrationContext);
    const { getSupplier, supplierName } = useContext(SupplierContext);
    const supplierNumber = localStorage.getItem('supplierNumber' as string);

    const handleCancelDelete = () => setShow(false);

    const handleDone = () => {
        setShow(false);
        navigate(`/supplier/${supplierNumber}`);
        getSupplier(supplierName);
    };

    return (
        <>
            <Modal centered show={show} onHide={handleCancelDelete} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>A supplier integration has been created!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    A {createdIntegrationObject.eventName} for supplier {createdIntegrationObject.plugin} has been successfully created.
                </Modal.Body>
                <Modal.Footer>
                    <Button className={oKButton} onClick={handleDone}>
                        Cool!
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreatedIntegration;
