import React, { useContext, useEffect } from 'react';
import { IntegrationsOverviewContext } from '../../components/integrationsOverview/integrationsOverviewContext';
import { IntegrationsOverview } from '../../components/integrationsOverview/integrationsOverview';

export const IntegrationsOverviewPage: React.FC = () => {
    const { fetchIntegrations, integrations } = useContext(IntegrationsOverviewContext);
    const supplierIntegrations = JSON.parse(localStorage.getItem('supplierIntegrations') as string);

    useEffect(() => {
        if (Object.keys(integrations).length === 0) {
            fetchIntegrations();
        }
    }, [supplierIntegrations, integrations]);

    return <>{supplierIntegrations !== undefined && <IntegrationsOverview />}</>;
};
