import React, { useContext } from 'react';
import ToolkitProvider, { Search, SearchBarProps, ToolkitContextType } from 'react-bootstrap-table2-toolkit';
import { title, contractHeader, headerContent, headerClass, contractsOverviewWrapper } from './../../integrationsOverview/integrationsOverview.module.scss';
import { header, searchField } from './../../ellaSuppliersOverview/suppliersOverview.module.scss';
import filterFactory from 'react-bootstrap-table2-filter';
import BootstrapTable, { PaginationOptions } from 'react-bootstrap-table-next';
import { useNavigate } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
const { SearchBar, ClearSearchButton } = Search;

export const ContractsOverview: React.FC = () => {
    const navigate = useNavigate();
    const supplierName: string = localStorage.getItem('supplierName') as string;
    const supplierNumber: string = localStorage.getItem('supplierNumber') as string;
    const supplierContracts = JSON.parse(localStorage.getItem('supplierContracts') as string);

    console.log(supplierContracts);

    const handleClick = () => {
        navigate(`/supplier/${supplierNumber}`);
    };

    const booleanToReadable = (row: any) => {
        if (row === true) {
            return <div>Yes</div>;
        } else {
            return <div>No</div>;
        }
    };

    // table headers
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
        },
        {
            dataField: 'supplierNumber',
            text: 'Supplier number',
            sort: true,
        },
        {
            dataField: 'current',
            text: 'Active',
            sort: true,
            formatter: booleanToReadable,
            style: (row: boolean) => {
                if (row) {
                    return {
                        backgroundColor: '#C6FFC1',
                    };
                } else {
                    return {
                        backgroundColor: '#ECECEC',
                    };
                }
            },
        },
        {
            dataField: 'model',
            text: 'Model',
            sort: true,
        },
        {
            dataField: 'feePercentage',
            text: 'Fee percentage',
            sort: true,
        },
        {
            dataField: 'returnPolicy',
            text: 'Return policy',
            sort: true,
            formatter: booleanToReadable,
        },
        {
            dataField: 'smallBusinessScheme',
            text: 'Small business scheme',
            sort: true,
            formatter: booleanToReadable,
        },
        {
            dataField: 'refundFeeForReturns',
            text: 'Refund fee for returns',
            sort: true,
            formatter: booleanToReadable,
        },
        {
            dataField: 'beginDate.date',
            text: 'beginDate.date',
            sort: true,
        },
        {
            dataField: 'endDate',
            text: 'endDate',
            sort: true,
        },
    ];

    const options: PaginationOptions = {
        sizePerPage: 10,
        showTotal: true,
        alwaysShowAllBtns: true,
        hidePageListOnlyOnePage: true,
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '25',
                value: 25,
            },
            {
                text: 'All',
                value: supplierContracts.length,
            },
        ],
    };

    return (
        <>
            <ToolkitProvider keyField={'id'} data={supplierContracts} columns={columns}>
                {(props: ToolkitContextType) => (
                    <div className={contractsOverviewWrapper}>
                        <div className={contractHeader}>
                            <div className={title}>
                                <div onClick={handleClick}>
                                    <h4>{supplierName} -</h4>
                                </div>
                                <div>
                                    <h4>Contracts overview</h4>
                                </div>
                            </div>

                            <div className={headerContent}>
                                <div className={searchField}>
                                    <SearchBar {...props.searchProps} />
                                    <ClearSearchButton {...props.searchProps} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        {supplierContracts.length >= 10 ? (
                            <BootstrapTable
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory(options)}
                                bootstrap4
                                sort={{ dataField: 'id', order: 'asc' }}
                                striped
                                bordered={false}
                                noDataIndication="No supplier contracts found!"
                                headerClasses={headerClass}
                            />
                        ) : (
                            <BootstrapTable
                                {...props.baseProps}
                                filter={filterFactory()}
                                bootstrap4
                                sort={{ dataField: 'id', order: 'asc' }}
                                striped
                                bordered={false}
                                noDataIndication="No supplier contracts found!"
                                headerClasses={headerClass}
                            />
                        )}
                    </div>
                )}
            </ToolkitProvider>
        </>
    );
};
