/* eslint @typescript-eslint/no-unsafe-return: 0 */

import React, { useContext, useState } from 'react';
import gatewayApiInstance from '../../../services/gatewayAPI/gatewayApiInstance/gatewayApiInstance';
import { integrationInterface } from '../../suppliersOverview/suppliersOverviewContext';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { CreateIntegrationContext } from '../createIntegraton/createIntegrationContext';
import {
    ftpOrderFieldErrorsInitialState,
    ftpOrderFieldErrorsInterface,
    ftpShipmentFieldErrorsInitialState,
    ftpShipmentFieldErrorsInterface,
    ftpStockFieldErrorsInitialState,
    ftpStockFieldErrorsInterface,
} from './integrationServiceInterfaces';

// interfaces
export interface IntegrationServiceContextInterface {
    viewMode: boolean;
    setViewMode: (state: boolean) => void;
    showDeleteSuccessModal: boolean;
    setShowDeleteSuccessModal: (state: boolean) => void;
    getIntegration: (supplierName: string, integrationType: string) => Record<any, any>;
    editIntegration: () => void;
    integration: integrationInterface;
    stock: Record<any, any>;
    setStock: (state: any) => void;
    editIntegrationObject: integrationInterface;
    setIntegration: (state: any) => void;
    computeDelimiter: (integrationType: any) => JSX.Element;
    formatISOtoReadable: (date: string) => JSX.Element;
    config: Record<any, any>;
    setConfig: (state: any) => void;

    // ftp error field init states and interfaces
    ftpStockFieldErrors: ftpStockFieldErrorsInterface;
    setFtpStockFieldErrors: (state: any) => void;
    ftpShipmentFieldErrors: ftpShipmentFieldErrorsInterface;
    setFtpShipmentFieldErrors: (state: any) => void;
    ftpOrderFieldErrors: ftpOrderFieldErrorsInterface;
    setFtpOrderFieldErrors: (state: any) => void;
}

export const IntegrationServiceContext = React.createContext<IntegrationServiceContextInterface>({} as IntegrationServiceContextInterface);

export const IntegrationServiceProvider: React.FC = ({ children }) => {
    const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
    const [viewMode, setViewMode] = useState(true);
    const [stock, setStock] = useState({} as integrationInterface);

    const [ftpStockFieldErrors, setFtpStockFieldErrors] = useState({ ...ftpStockFieldErrorsInitialState } as ftpStockFieldErrorsInterface);
    const [ftpShipmentFieldErrors, setFtpShipmentFieldErrors] = useState({ ...ftpShipmentFieldErrorsInitialState } as ftpShipmentFieldErrorsInterface);
    const [ftpOrderFieldErrors, setFtpOrderFieldErrors] = useState({ ...ftpOrderFieldErrorsInitialState } as ftpOrderFieldErrorsInterface);

    const [integration, setIntegration] = useState({} as integrationInterface);
    const { config, setConfig } = useContext(CreateIntegrationContext);
    const [editIntegrationObject, setEditIntegrationObject] = useState({} as integrationInterface);

    const supplierNumber = localStorage.getItem('supplierNumber' as string);
    const navigate = useNavigate();

    const formatISOtoReadable = (date: string): JSX.Element => {
        return <>{dayjs(date).toString()}</>;
    };

    const getIntegration = async (supplierName: string, integrationType: string) => {
        const response = await gatewayApiInstance.get(`/suppliers-integrations/${supplierName}/${integrationType}`);
        if (response.status === 200) {
            setIntegration(response.data);
            console.log(response.data);
            return response.data;
        } else {
            console.log(response);
        }
    };

    const editIntegration = async () => {
        if (!integration) {
            console.log('Config has not changed');
        }

        const data: Record<string, unknown> = {
            ...integration,
        };
        console.log(integration);
        console.log(data);

        const response = await gatewayApiInstance.patch(`/suppliers-integrations/${integration.plugin}/${integration.id}`, { ...data });
        if (response.status === 200) {
            console.log(response.data);
        } else {
            console.log(response);
        }
    };

    const computeDelimiter = (integrationType: any) => {
        if (integrationType.config?.delimiter === ';') {
            return <>&#x0003B; - semicolon</>;
        } else if (integrationType.config?.delimiter === ',') {
            return <>&#x0002C; - comma</>;
        } else {
            return <>not valid</>;
        }
    };

    return (
        <IntegrationServiceContext.Provider
            value={{
                viewMode,
                setViewMode,
                showDeleteSuccessModal,
                setShowDeleteSuccessModal,
                getIntegration,
                editIntegration,
                stock,
                setStock,
                integration,
                editIntegrationObject,
                setIntegration,
                computeDelimiter,
                formatISOtoReadable,
                ftpStockFieldErrors,
                setFtpStockFieldErrors,
                ftpShipmentFieldErrors,
                setFtpShipmentFieldErrors,
                ftpOrderFieldErrors,
                setFtpOrderFieldErrors,
                config,
                setConfig,
            }}
        >
            {children}
        </IntegrationServiceContext.Provider>
    );
};
