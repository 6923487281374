import React, { useContext } from 'react';
import { ftpStockFieldErrorsInterface, ftpShipmentFieldErrorsInterface, ftpOrderFieldErrorsInterface } from '../integrationService/integrationServiceInterfaces';
import { IntegrationServiceContext } from '../integrationService/integrationServiceContext';

// interfaces
export interface formValidationServiceInterface {
    validateFormMainFunction: (config: Record<any, any>, integrationName: string) => boolean;
}

export const FormValidationServiceContext = React.createContext<formValidationServiceInterface>({} as formValidationServiceInterface);

export const FormValidationServiceProvider: React.FC = ({ children }) => {
    const { ftpStockFieldErrors, setFtpStockFieldErrors, setFtpShipmentFieldErrors, setFtpOrderFieldErrors, config } = useContext(IntegrationServiceContext);

    const validateFormMainFunction = (config: Record<any, any>, integrationName: string): boolean => {
        let errorDetected = false;

        console.log(config);

        switch (integrationName) {
            case 'FTPstock':
                errorDetected = checkFieldsFtpStockForm(config);
                break;
            case 'FTPshipment':
                errorDetected = checkFieldsFtpShipmentForm(config);
                break;
            case 'FTPorder':
                errorDetected = checkFieldsFtpOrderForm(config);
                break;
        }

        if (errorDetected) {
            console.log(ftpStockFieldErrors);
        }

        return errorDetected;
    };

    const resourceCheck = (resource: string) => {
        let err: boolean;

        if (resource === '') {
            err = true;
            return err;
        }

        // const listOfValidProtocols = ['http', 'https', 'ftp', 'sftp'];
        // err = !listOfValidProtocols.some((validProtocol) => resource.includes(validProtocol));
    };

    // FTP stock
    const checkFieldsFtpStockForm = (config: Record<any, any>): boolean => {
        let checkFieldsResult = false;
        let tempFieldErrorObj: ftpStockFieldErrorsInterface = {} as ftpStockFieldErrorsInterface;

        if (resourceCheck(config.resource)) {
            tempFieldErrorObj = { ...tempFieldErrorObj, resourceErr: true };
            checkFieldsResult = true;
        }
        if (config.delimiter === '' && config.delimiter !== ',' && config.delimiter !== ';') {
            tempFieldErrorObj = { ...tempFieldErrorObj, delimiterErr: true };
            checkFieldsResult = true;
        }
        if (config.stockField === '') {
            tempFieldErrorObj = { ...tempFieldErrorObj, stockFieldErr: true };
            checkFieldsResult = true;
        }
        if (config.skuField === '') {
            tempFieldErrorObj = { ...tempFieldErrorObj, skuFieldErr: true };
            checkFieldsResult = true;
        }

        // set errors
        setFtpStockFieldErrors({ ...tempFieldErrorObj });
        return checkFieldsResult;
    };

    // FTP shipment
    const checkFieldsFtpShipmentForm = (config: Record<any, any>): boolean => {
        let checkFieldsResult = false;
        let tempFieldErrorObj: ftpShipmentFieldErrorsInterface = {} as ftpShipmentFieldErrorsInterface;

        if (config.resource === '') {
            tempFieldErrorObj = { ...tempFieldErrorObj, resourceErr: true };
            checkFieldsResult = true;
        }
        if (config.archiveResource === '') {
            tempFieldErrorObj = { ...tempFieldErrorObj, archiveResourceErr: true };
            checkFieldsResult = true;
        }
        if (config.delimiter === '') {
            tempFieldErrorObj = { ...tempFieldErrorObj, delimiterErr: true };
            checkFieldsResult = true;
        }
        if (config.identifierType === '') {
            tempFieldErrorObj = { ...tempFieldErrorObj, identifierTypeErr: true };
            checkFieldsResult = true;
        }
        if (config.trackTraceNumberField === '') {
            tempFieldErrorObj = { ...tempFieldErrorObj, trackTraceNumberFieldErr: true };
            checkFieldsResult = true;
        }
        if (config.carrierField === '') {
            tempFieldErrorObj = { ...tempFieldErrorObj, carrierFieldErr: true };
            checkFieldsResult = true;
        }
        if (config.identifierField === '') {
            tempFieldErrorObj = { ...tempFieldErrorObj, identifierFieldErr: true };
            checkFieldsResult = true;
        }
        if (config.skuField === '') {
            tempFieldErrorObj = { ...tempFieldErrorObj, skuFieldErr: true };
            checkFieldsResult = true;
        }
        if (config.quantityField === '') {
            tempFieldErrorObj = { ...tempFieldErrorObj, quantityFieldErr: true };
            checkFieldsResult = true;
        }

        // set errors
        setFtpStockFieldErrors({ ...tempFieldErrorObj });
        return checkFieldsResult;
    };

    // FTP order
    const checkFieldsFtpOrderForm = (config: Record<any, any>): boolean => {
        let checkFieldsResult = false;
        let tempFieldErrorObj: ftpOrderFieldErrorsInterface = {} as ftpOrderFieldErrorsInterface;

        if (config.resource === '') {
            tempFieldErrorObj = { ...tempFieldErrorObj, resourceErr: true };
            checkFieldsResult = true;
        }
        if (config.headerField === '') {
            tempFieldErrorObj = { ...tempFieldErrorObj, headerFieldErr: true };
            checkFieldsResult = true;
        }
        if (config.lineField === '') {
            tempFieldErrorObj = { ...tempFieldErrorObj, lineFieldErr: true };
            checkFieldsResult = true;
        }
        if (config.footerField === '') {
            tempFieldErrorObj = { ...tempFieldErrorObj, footerFieldErr: true };
            checkFieldsResult = true;
        }

        // set errors
        setFtpStockFieldErrors({ ...tempFieldErrorObj });
        return checkFieldsResult;
    };

    return (
        <FormValidationServiceContext.Provider
            value={{
                validateFormMainFunction,
            }}
        >
            {children}
        </FormValidationServiceContext.Provider>
    );
};
