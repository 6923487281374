import React, { useState } from 'react';
import { integrationInterface } from '../../../suppliersOverview/suppliersOverviewContext';

// interfaces
export interface stockContextInterface {
    stockObject: integrationInterface;
    stockConfig: stockConfigInterface;
    setStockObject: (state: any) => void;
    setStockConfig: (state: any) => void;
}
export interface stockConfigInterface {
    resource: string;
    delimiter: string;
    stockFieldAcceptableDescriptions: string[];
    stockFieldDefaultStockLevel: number;
    mapping: {
        eanField: string;
        skuField: string;
        priceField: string;
        stockField: string;
        compareAtPriceField: string;
    };
}

export interface stockIntegrationInterface extends integrationInterface {
    config: {
        platform?: string;
        resource?: string;
        delimiter?: string;
        stockFieldAcceptableDescriptions?: string[];
        stockFieldDefaultStockLevel?: number;
        mapping?: {
            eanField: string;
            skuField: string;
            priceField: string;
            stockField: string;
            compareAtPriceField: string;
        };
    };
}

export const StockContext = React.createContext<stockContextInterface>({} as stockContextInterface);

export const StockProvider: React.FC = ({ children }) => {
    const [stockConfig, setStockConfig] = useState({} as stockConfigInterface);
    const [stockObject, setStockObject] = useState({} as stockIntegrationInterface);

    return (
        <StockContext.Provider
            value={{
                stockObject,
                stockConfig,
                setStockObject,
                setStockConfig,
            }}
        >
            {children}
        </StockContext.Provider>
    );
};
