import React, { useContext, useState } from 'react';
import '../integrationForm.module.scss';
import { Button, Col, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import { CreateIntegrationContext, createIntegrationInterface } from './createIntegrationContext';
import { integrationFormWrapper, integrationFormHeader, integrationForm, controlButtonsWrapper } from '../integrationForm.module.scss';
import {
    createIntegrationForm,
    integrationType,
    label,
    result,
    integrationTypeHeader,
    integrationPlatform,
    integrationMarketplace,
    integrationMarketplaceHeader,
    integrationPlatformHeader,
    configClass,
    setAsActive,
    isActiveRes,
    createButton,
} from './createIntegration.module.scss';
import CreatedIntegration from '../../uiComponents/modals/createdIntegration';
import InfoModal from '../../uiComponents/modals/infoModal';
import { useNavigate } from 'react-router-dom';

export const CreateIntegration: React.FC = () => {
    const navigate = useNavigate();
    const { setIntegrationInput, integrationInput, constructNewIntegration, hasErrors, showCreatedModal, setShowCreatedModal, setHasErrors } = useContext(CreateIntegrationContext);
    const [validated, setValidated] = useState(false);
    const supplierName = localStorage.getItem('supplierName' as string);

    // validate form

    // handle buttons
    const handleSubmit = (e: { preventDefault: () => void; currentTarget: any; stopPropagation: () => void }) => {
        e.preventDefault();
        console.log(integrationInput);

        constructNewIntegration(integrationInput);
    };

    // handle dropdowns
    const handleIntegrationType = (e: any) => {
        setIntegrationInput({ ...integrationInput, eventName: e });
    };
    const handlePlatformType = (e: any) => {
        setIntegrationInput({ ...integrationInput, platform: e });
    };
    const handleMarketplace = (e: any) => {
        setIntegrationInput({ ...integrationInput, tempMarketplace: e });
    };

    const handleIsActiveState = () => {
        if (integrationInput.isActive) {
            setIntegrationInput({ ...integrationInput, isActive: false });
        } else {
            setIntegrationInput({ ...integrationInput, isActive: true });
        }
    };

    // show the type of platform settings based on the platform which is set at the top of the form
    const renderIntegrationPlatformConfig = () => {
        let platform = '';
        let type: string[] = [''];

        if (integrationInput.platform && integrationInput.eventName) {
            platform = integrationInput.platform.toUpperCase();
            type = integrationInput.eventName.split('.', 2);
        }
        const settingsComponent = `${platform}${type[1]}`;

        // get the platform component
        const Component = require(`./createIntegrationsTypesForms/${settingsComponent}`).default;

        // render the component
        return <Component />;
    };

    let show = false;
    if (integrationInput.eventName && integrationInput.platform) {
        show = true;
    }

    return (
        <>
            <div className={integrationFormWrapper}>
                <header className={integrationFormHeader}>
                    <h4>Create integration</h4>
                </header>

                <hr />

                <Form className={`${integrationForm} ${createIntegrationForm}`}>
                    <div className={integrationType}>
                        <Form.Label className={`${label} ${integrationTypeHeader}`} column sm={4}>
                            Type
                        </Form.Label>
                        <Col sm={4}>
                            <DropdownButton className="typeDropdown" id="dropdown-basic-button" title="type" onSelect={handleIntegrationType}>
                                <Dropdown.Item id="stock" eventKey="fetch.stock">
                                    Stock
                                </Dropdown.Item>
                                <Dropdown.Item id="shipment" eventKey="fetch.shipment">
                                    Shipment
                                </Dropdown.Item>
                                <Dropdown.Item id="order" eventKey="create.order">
                                    Order
                                </Dropdown.Item>
                            </DropdownButton>
                        </Col>
                        <div className={result}>{integrationInput.eventName}</div>
                    </div>

                    <div className={integrationPlatform}>
                        <Form.Label className={`${label} ${integrationPlatformHeader}`} column sm={4}>
                            Platform
                        </Form.Label>
                        <Col sm={4}>
                            <DropdownButton className="platformDropdown" id="dropdown-basic-button" title="type" onSelect={handlePlatformType}>
                                <Dropdown.Item id="FTP" eventKey="FTP">
                                    FTP
                                </Dropdown.Item>
                                {/*<Dropdown.Item eventKey="MAGENTO_2">Magento 2</Dropdown.Item>*/}
                            </DropdownButton>
                        </Col>
                        <div className={result}>{integrationInput.platform}</div>
                    </div>

                    <div className={integrationMarketplace}>
                        <Form.Label className={`${label} ${integrationMarketplaceHeader}`} column sm={4}>
                            Marketplace
                        </Form.Label>
                        <Col sm={4}>
                            <DropdownButton className="marketplaceDropdown" id="dropdown-basic-button" title="type" onSelect={handleMarketplace}>
                                <Dropdown.Item id="NL" eventKey="NL">
                                    Netherlands
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="DE">Germany</Dropdown.Item>
                                {/*<Dropdown.Item eventKey="FR">France</Dropdown.Item>*/}
                            </DropdownButton>
                        </Col>
                        <div className={result}>{integrationInput.tempMarketplace}</div>
                    </div>

                    <div className={setAsActive}>
                        <Form.Group as={Row} className={`mb-4  active`}>
                            <Form.Label className={label} column sm={4}>
                                Activate integration
                            </Form.Label>
                            <Col sm={1}>
                                <Form.Check type="checkbox" defaultChecked onClick={handleIsActiveState} />
                            </Col>
                        </Form.Group>

                        <div className={`${isActiveRes} ${result}`}>{integrationInput.isActive ? 'Yes' : 'No'}</div>
                    </div>

                    {/* settings */}
                    <div className={configClass}>{show ? renderIntegrationPlatformConfig() : ''}</div>

                    <div className={`${controlButtonsWrapper} ${createButton}`}>
                        <Button type="submit" onClick={handleSubmit}>
                            Create
                        </Button>
                    </div>
                </Form>
            </div>
            {hasErrors && <InfoModal info={`Integration could not be created because some fields are not valid!`} show={hasErrors} setShow={setHasErrors} timeout={false} />}
            <CreatedIntegration show={showCreatedModal} setShow={setShowCreatedModal} />
        </>
    );
};
