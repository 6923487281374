import React, { useEffect } from 'react';
import { aboutWrapper, aboutContent } from './about.module.scss';

export const AboutPage: React.FC = () => {
    return (
        <>
            <div className={aboutWrapper}>
                <div className={aboutContent}>
                    <p>Hey!</p>
                    <p>The Operations Portal is developed by Thibaux Swildens.</p>
                </div>
            </div>
        </>
    );
};
