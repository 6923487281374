import React, { useContext, useEffect } from 'react';
import { EllaSuppliersOverview } from '../../components/ellaSuppliersOverview/ellasuppliersOverview';
import { EllaSuppliersOverviewContext } from '../../components/ellaSuppliersOverview/ellasuppliersOverviewContext';

export const EllaSupplierOverviewPage: React.FC = () => {
    const { suppliers, fetchSuppliers } = useContext(EllaSuppliersOverviewContext);

    useEffect(() => {
        if (typeof suppliers === 'object') {
            fetchSuppliers();
        }
    }, []);

    return <>{suppliers !== undefined && <EllaSuppliersOverview />}</>;
};
