import React, { useContext, useEffect, useState } from 'react';
import BootstrapTable, { PaginationOptions } from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search, SearchBarProps, ToolkitContextType } from 'react-bootstrap-table2-toolkit';
import { useNavigate } from 'react-router-dom';
import { EllaSuppliersOverviewContext } from './ellasuppliersOverviewContext';
import { SupplierContext } from '../supplier/supplier/supplierContext';
import { title, searchField, header, supplierNameHeader, supplierNameCol, onHover } from './suppliersOverview.module.scss';
import { headerClass } from '../integrationsOverview/integrationsOverview.module.scss';
import paginationFactory from 'react-bootstrap-table2-paginator';

const { SearchBar, ClearSearchButton } = Search;

export const EllaSuppliersOverview: React.FC = () => {
    const { suppliers } = useContext(EllaSuppliersOverviewContext);
    const { setSupplierName, setSupplierJettiId, fetchSupplierInfo, fetchSupplierIntegrations } = useContext(SupplierContext);
    const navigate = useNavigate();

    const setSupplierFunction = (rowIndex: any) => {
        let f = rowIndex.name;
        setSupplierName(f);
        setSupplierJettiId(rowIndex.number);
        localStorage.setItem('supplierName', rowIndex.name);
        localStorage.setItem('supplierNumber', rowIndex.number);

        fetchSupplierInfo();
        fetchSupplierIntegrations();
    };

    const onHoverClick = (row: any) => {
        return <div className={onHover}>{row}</div>;
    };

    const supplierNameHeaderFormatter = (column: any, colIndex: any) => {
        return <div className={supplierNameHeader}>{column.text}</div>;
    };
    const supplierNameColFormatter = (row: any) => {
        return <div className={`${onHover} ${supplierNameCol}`}>{row}</div>;
    };

    // table headers
    const columns = [
        {
            dataField: 'number',
            text: 'Jetti ID',
            sort: true,
            formatter: onHoverClick,
        },
        {
            dataField: 'name',
            text: 'Supplier Name',
            sort: true,
            headerFormatter: supplierNameHeaderFormatter,
            formatter: supplierNameColFormatter,
            events: {
                onClick: (e: any, cell: any, row: any, rowIndex: any) => {
                    setSupplierFunction(rowIndex);
                    navigate(`/supplier/${rowIndex.number}`);
                },
            },
        },
        {
            dataField: 'skuPrefix',
            text: 'SKU Prefix',
            sort: true,
        },
    ];

    const options: PaginationOptions = {
        sizePerPage: 10,
        showTotal: true,
        alwaysShowAllBtns: true,
        hidePageListOnlyOnePage: true,
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '25',
                value: 25,
            },
            {
                text: '50',
                value: 50,
            },
            {
                text: '100',
                value: 100,
            },
            {
                text: 'All',
                value: suppliers.length,
            },
        ],
    };

    return (
        <>
            <ToolkitProvider keyField={'id'} data={suppliers} columns={columns} search>
                {(props: ToolkitContextType) => (
                    <div className="suppliersOverviewWrapper">
                        <div className={header}>
                            <div className={title}>
                                <h2 className="pageTitle">Suppliers overview</h2>
                            </div>

                            <div className={searchField}>
                                <SearchBar {...props.searchProps} />
                                <ClearSearchButton {...props.searchProps} />
                            </div>
                        </div>
                        <hr />
                        <BootstrapTable
                            {...props.baseProps}
                            filter={filterFactory()}
                            pagination={paginationFactory(options)}
                            bootstrap4
                            sort={{ dataField: 'id', order: 'asc' }}
                            striped
                            hover
                            bordered={false}
                            noDataIndication="No suppliers found, contact your local developer!"
                            headerClasses={headerClass}
                        />
                    </div>
                )}
            </ToolkitProvider>
        </>
    );
};
