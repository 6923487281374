import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export const SupplierButton: React.FC = () => {
    const navigate = useNavigate();
    const supplierName = localStorage.getItem('supplierName' as string);
    const supplierNumber = localStorage.getItem('supplierNumber' as string);

    return (
        <>
            <Button size="sm" className="button icon-left" onClick={() => navigate(`/supplier/${supplierNumber}`)}>
                {supplierName}
            </Button>
        </>
    );
};
