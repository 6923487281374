import React, { useContext, useEffect, useState } from 'react';
import gatewayApiInstance from '../../../services/gatewayAPI/gatewayApiInstance/gatewayApiInstance';
import { FTPstockConfigMappingInterface } from './createIntegrationsTypesForms/FTPstock';

// interfaces
export interface createIntegrationContext {
    createIntegrationObj: createIntegrationInterface;
    setCreateIntegrationObj: (state: any) => any;
    constructNewIntegration: (integration: integrationInputInterface) => void;
    integrationObject: integrationObjectInterface;
    setIntegrationObject: (state: any) => any;
    showCreatedModal: boolean;
    setShowCreatedModal: (state: boolean) => void;
    createdIntegrationObject: integrationObjectInterface;
    createIntegrationErrors: Record<string, unknown>;
    hasErrors: boolean;
    setHasErrors: (state: boolean) => void;
    config: configInterface;
    setConfig: (string: any) => void;
    integrationInput: integrationInputInterface;
    setIntegrationInput: (state: any) => void;
    isSubmitting: boolean;
}

interface useFormInterface {
    handleChange: any;
    handleSubmit: any;
    values: any;
    errors: any;
}

export interface integrationObjectInterface {
    eventName: string;
    schedule: string;
    plugin: string;
    platform: string;
    config: Record<string, unknown>;
    supplier: {
        id: string;
    };
    isActive: boolean;
}

// integration to nio interface
export interface createIntegrationInterface {
    eventName: string;
    plugin: string;
    schedule: string;
    isActive: boolean;
    config: configInterface;
    marketplace: {
        id: string;
    };
    supplier: {
        name: string;
        referenceId?: string;
    };
}

export interface configInterface {
    resource: string;
    delimiter: string;
    platform: string;
    identifierType?: string;
    archiveResource?: string;
    mapping: configMappingInterface;
}

export interface configMappingInterface {
    priceField?: string;
    skuField?: string;
    compareAtPriceField?: string;
    stockField?: string;
    eanField?: string;
    headerField?: string;
    lineField?: string;
    footerField?: string;
    identifierField?: string;
    trackTraceNumberField?: string;
    carrierField?: string;
    quantityField?: string;
}

// user input interface
export interface integrationInputInterface {
    platform: string;
    eventName: string;
    plugin: string;
    schedule: string;
    isActive: boolean;
    tempMarketplace: string;
    marketplace: {
        id: string;
    };
    supplier: {
        name: string;
        referenceId?: string;
    };
    config: configInputInterface;
}

interface configInputInterface {
    resource: string;
    platform: string;
    archiveResource: string;
    identifierType: string;
    delimiter: string;
    marketplace: string;
    mapping: configMappingInterface;
}

export const CreateIntegrationContext = React.createContext<createIntegrationContext>({} as createIntegrationContext);

export const CreateIntegrationProvider: React.FC = ({ children }) => {
    const supplierName = localStorage.getItem('supplierName' as string);
    const supplierNumber = sessionStorage.getItem('supplierNumber' as string);

    const [integrationInput, setIntegrationInput] = useState({
        platform: '',
        eventName: '',
        plugin: '',
        schedule: '',
        isActive: true,
        config: {
            resource: '',
            delimiter: '',
            platform: '',
            archiveResource: '',
            identifierType: '',
        },
        supplier: {
            name: '',
        },
        marketplace: {
            id: '',
        },
    } as integrationInputInterface);

    const [createIntegrationObj, setCreateIntegrationObj] = useState({
        isActive: true,
    } as createIntegrationInterface);

    const [integrationObject, setIntegrationObject] = useState({
        eventName: '',
        platform: '',
    } as integrationObjectInterface);
    const [showCreatedModal, setShowCreatedModal] = useState(false);
    const [createdIntegrationObject, setCreatedIntegrationObject] = useState({} as integrationObjectInterface);
    const [createIntegrationErrors, setCreateIntegrationErrors] = useState({});
    const [hasErrors, setHasErrors] = useState(false);

    const [config, setConfig] = useState({} as configInterface);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // construct the integration
    const constructNewIntegration = async (integration: integrationInputInterface) => {
        if (!integration) {
            console.log('Config is empty');
        }

        // set global or standard values
        integration['schedule'] = '0 0 5 31 2 ?';
        integration.plugin = `${supplierName}`;

        // set supplier & marketplace
        // integration.supplier.referenceId = `${supplierNumber}`;
        integration.supplier.name = `${supplierName}`;
        switch (integration.tempMarketplace) {
            case 'NL':
                integration.marketplace = {
                    id: '9c8f834c-8aad-4fa1-8ab4-3edaeb2c52b6',
                };
                break;
            case 'DE':
                integration.marketplace = {
                    id: '53224343-8f8b-4f5f-8d0d-f20e4d398619',
                };
                break;
            case 'FR':
                integration.marketplace = {
                    id: 'fr_FR',
                };
                break;
        }
        // @ts-ignore
        delete integration.tempMarketplace;

        // Copy platform field to config object
        // const platform = integration.platform;
        integration.config['platform'] = integration.platform;
        // @ts-ignore
        delete integration['platform'];

        await postNewIntegration(integration);
    };

    // post the new integration to nio
    const postNewIntegration = async (integration: createIntegrationInterface) => {
        const data: Record<string, unknown> = {
            ...integration,
        };

        console.log(data);

        try {
            const response = await gatewayApiInstance.post(`/suppliers-integrations/${supplierName}`, { ...data });
            if (response.status === 200) {
                setShowCreatedModal(true);
                setCreatedIntegrationObject(response.data.result);
                console.log(response.data);
            }
        } catch (e) {
            console.log(e);
            // @ts-ignore
            const errors = JSON.parse(e.response.data.message);
            console.log(errors);
            setHasErrors(true);
            setCreateIntegrationErrors(errors);
            return errors as Record<string, unknown>;
        }
    };

    return (
        <CreateIntegrationContext.Provider
            value={{
                createIntegrationObj,
                setCreateIntegrationObj,
                constructNewIntegration,
                integrationObject,
                setIntegrationObject,
                showCreatedModal,
                setShowCreatedModal,
                createdIntegrationObject,
                createIntegrationErrors,
                hasErrors,
                setHasErrors,
                config,
                setConfig,
                integrationInput,
                setIntegrationInput,
                isSubmitting,
            }}
        >
            {children}
        </CreateIntegrationContext.Provider>
    );
};
