import React, { useContext, useEffect } from 'react';
import { supplierHeaderWrapper, left, right, center, navBarWrapper, contractsItem, title, navBar, supplierNumberClass, navButtons } from './supplierHeader.module.scss';
import { ellaSupplierInfoInterface, supplierInfoInterface } from '../supplier/supplierInterfaces';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IntegrationServiceContext } from '../../integrations/integrationService/integrationServiceContext';
import { SupplierContext } from '../supplier/supplierContext';
import { BackButton } from '../../uiComponents/buttons/backButton';
import { SupplierButton } from '../../uiComponents/buttons/supplierButton';

const SupplierHeader: React.FC = () => {
    const navigate = useNavigate();
    const { getIntegration } = useContext(IntegrationServiceContext);
    let { supplierInfo, integrationsNames, fetchSupplierIntegrations } = useContext(SupplierContext);
    const info: ellaSupplierInfoInterface = JSON.parse(sessionStorage.getItem('supplierInfo') as string);
    const supplierNumber = localStorage.getItem('supplierNumber') as string;
    const supplierName = localStorage.getItem('supplierName') as string;
    const names = localStorage.getItem('integrationsNames') as unknown as string[];
    let supplier: ellaSupplierInfoInterface = {} as ellaSupplierInfoInterface;

    if (!supplierInfo) {
        supplier = info;
    } else {
        supplier = supplierInfo;
    }

    // set integration types to use in the route
    const setIntegrationTypes = (integrationsNames: string[]) => {
        let integrationTypes = [];
        for (let i = 0; i < integrationsNames.length; i++) {
            const route = integrationsNames[i].split('.', 2);
            integrationTypes.push(route[1]);
        }
        return integrationTypes;
    };
    const integrationTypes = setIntegrationTypes(integrationsNames);

    // handle buttons functions
    const handleClickToIntegration = (integration: string, idx: number) => {
        console.log(integration);

        getIntegration(supplierName, integration);
        navigate(`/supplier/${supplierNumber}/${integrationTypes[idx]}`);
    };

    useEffect(() => {
        fetchSupplierIntegrations();
    }, []);

    return (
        <>
            <div className={supplierHeaderWrapper}>
                <div className={navButtons}>
                    <BackButton />
                    <SupplierButton />
                </div>

                <div className={left}>
                    <h2>{supplierName}</h2>
                    <p className={supplierNumberClass}>
                        number:
                        <a href={`https://app.jetti.io/dropship-providers/${supplierNumber}?tab=contact`}>{supplierNumber}</a>
                    </p>
                </div>

                <div className={center}>
                    <div className={navBarWrapper}>
                        <Navbar expand="sm" variant="light" className={navBar}>
                            <Container>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto">
                                        <Nav.Link href={`/supplier/${supplierNumber}/integrations`}>Supplier's integrations</Nav.Link>
                                        {/* integrations */}
                                        <NavDropdown title="Integrations" id="basic-nav-dropdown">
                                            {integrationsNames.map((integration: string, idx: number) => {
                                                return (
                                                    <>
                                                        <NavDropdown.Item key={idx} onClick={() => handleClickToIntegration(integration, idx)}>
                                                            {integration}
                                                        </NavDropdown.Item>
                                                    </>
                                                );
                                            })}
                                        </NavDropdown>

                                        {/* contracts */}
                                        <Nav.Link className={contractsItem} href={`/supplier/${supplierNumber}/contracts`}>
                                            Contracts
                                        </Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </div>
                </div>

                <div className={right} />
            </div>
        </>
    );
};

export default SupplierHeader;
