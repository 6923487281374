import React, { useContext, useEffect, useState } from 'react';
import {
    viewLabels,
    delimiter,
    labels,
    integrationForm,
    mappingForm,
    integrationFormSubHeader,
    mappingWrapper,
    subFormTitle,
    dateInfo,
    dateStringClass,
    generalSettingsClass,
    isActiveClass,
} from '../integrationForm.module.scss';
import { Col, Form, Row } from 'react-bootstrap';
import { IntegrationServiceContext } from '../integrationService/integrationServiceContext';
import { configMappingInterface, CreateIntegrationContext } from '../createIntegraton/createIntegrationContext';
import { errClass } from './styles/formStyles.module.scss';
import { ftpShipmentFieldErrorsInitialState, ftpStockFieldErrorsInitialState } from '../integrationService/integrationServiceInterfaces';

interface Props {
    viewMode: boolean;
}

const FTPshipment: React.FC<Props> = ({ viewMode }: Props): JSX.Element => {
    const { integration, setIntegration, formatISOtoReadable, computeDelimiter, ftpShipmentFieldErrors, setFtpShipmentFieldErrors } = useContext(IntegrationServiceContext);
    const { config, setConfig } = useContext(CreateIntegrationContext);

    const shipment = integration;
    console.log(shipment);

    const [mapping, setMapping] = useState<configMappingInterface>({} as configMappingInterface);

    const handleChangeToFirstMappingField = (evt: any, value: string) => {
        setMapping({ [value]: evt.target.value });
        setConfig({ ...config.mapping, mapping });
        handleChangeToIntegrationObject();
    };

    const handleChangeToSecMappingField = (evt: any, value: string) => {
        setMapping({ [value]: evt.target.value });
        setConfig({ ...config.mapping, mapping });
        handleChangeToIntegrationObject();
    };

    const handleChangeToConfig = (evt: any, value: string) => {
        setConfig({ ...config, [value]: evt.target.value });
        handleChangeToIntegrationObject();
    };
    const handleChangeToIntegrationObject = () => {
        setIntegration({ ...shipment, config });
        localStorage.setItem('INTEGRATION', JSON.stringify(integration));
    };

    const handleIsActiveState = () => {
        if (shipment.isActive) {
            setIntegration({ ...shipment, isActive: false });
        } else {
            setIntegration({ ...shipment, isActive: true });
        }
    };

    useEffect(() => {
        setFtpShipmentFieldErrors({ ...ftpShipmentFieldErrorsInitialState });
    }, []);

    return (
        <>
            <div>
                <div className={subFormTitle}>
                    <h4>{shipment.integrationName} Settings</h4>
                </div>
                <hr />

                <div className={dateInfo}>
                    <div>
                        Last update at:
                        <div className={dateStringClass}>{formatISOtoReadable(shipment.updatedAt)}</div>
                    </div>
                    <div>
                        Created at:
                        <div className={dateStringClass}> {formatISOtoReadable(shipment.createdAt)}</div>
                    </div>
                </div>

                <Form className={integrationForm}>
                    <div className={generalSettingsClass}>
                        {/* resource */}
                        <Row>
                            <Form.Group as={Col} className="mb-2" controlId="resourceUrl" sm={9}>
                                <Form.Label column sm={2} className={labels}>
                                    Url
                                </Form.Label>
                                <Col>
                                    {viewMode ? (
                                        <p className={viewLabels}>{shipment.config?.resource}</p>
                                    ) : (
                                        [
                                            ftpShipmentFieldErrors.resourceErr ? (
                                                <Form.Control
                                                    className={errClass}
                                                    defaultValue={shipment.config?.resource}
                                                    onChange={(e) => {
                                                        handleChangeToConfig(e, 'resource');
                                                    }}
                                                    type="text"
                                                    placeholder="sftp://username:password@host/shipmentInfo/VoorraadLijst.csv"
                                                />
                                            ) : (
                                                <Form.Control
                                                    defaultValue={shipment.config?.resource}
                                                    onChange={(e) => {
                                                        handleChangeToConfig(e, 'resource');
                                                    }}
                                                    type="text"
                                                    placeholder="sftp://username:password@host/shipmentInfo/VoorraadLijst.csv"
                                                />
                                            ),
                                        ]
                                    )}
                                </Col>
                            </Form.Group>
                            {/*archiveResource*/}
                            <Form.Group as={Col} className="mb-2" controlId="resourceUrl" sm={9}>
                                <Form.Label column sm={2} className={labels}>
                                    Archive url
                                </Form.Label>
                                <Col>
                                    {viewMode ? (
                                        <p className={viewLabels}>{shipment.config?.archiveResource}</p>
                                    ) : (
                                        [
                                            ftpShipmentFieldErrors.archiveResourceErr ? (
                                                <Form.Control
                                                    className={errClass}
                                                    defaultValue={shipment.config?.archiveResource}
                                                    onChange={(e) => {
                                                        handleChangeToConfig(e, 'archiveResource');
                                                    }}
                                                    type="text"
                                                    placeholder="sftp://username:password@host/shipmentInfo/archive"
                                                />
                                            ) : (
                                                <Form.Control
                                                    defaultValue={shipment.config?.archiveResource}
                                                    onChange={(e) => {
                                                        handleChangeToConfig(e, 'archiveResource');
                                                    }}
                                                    type="text"
                                                    placeholder="sftp://username:password@host/shipmentInfo/archive"
                                                />
                                            ),
                                        ]
                                    )}
                                </Col>
                            </Form.Group>

                            {/*identifierType*/}
                            <Form.Group as={Col} className="mb-2" controlId="resourceUrl" sm={9}>
                                <Form.Label column sm={4} className={labels}>
                                    Identifier type
                                </Form.Label>
                                <Col>
                                    {viewMode ? (
                                        <p className={viewLabels}>{shipment.config?.identifierType !== '' ? shipment.config?.identifierType : shipment.config?.identifierType}</p>
                                    ) : (
                                        [
                                            ftpShipmentFieldErrors.identifierTypeErr ? (
                                                <Form.Control
                                                    className={errClass}
                                                    defaultValue={shipment.config?.identifierType !== '' ? shipment.config?.identifierType : shipment.config?.identifierType}
                                                    onChange={(e) => {
                                                        handleChangeToConfig(e, 'identifierType');
                                                    }}
                                                    type="text"
                                                    placeholder="identifierType"
                                                />
                                            ) : (
                                                <Form.Control
                                                    defaultValue={shipment.config?.identifierType !== '' ? shipment.config?.identifierType : shipment.config?.identifierType}
                                                    onChange={(e) => {
                                                        handleChangeToConfig(e, 'identifierType');
                                                    }}
                                                    type="text"
                                                    placeholder="identifierType"
                                                />
                                            ),
                                        ]
                                    )}
                                </Col>
                            </Form.Group>

                            {/*delimiter*/}
                            <Form.Group as={Col} className="mb-4" controlId="resourceUrl" sm={2}>
                                <Form.Label column sm={2} className={labels}>
                                    Delimiter
                                </Form.Label>
                                <Col>
                                    {viewMode ? (
                                        <p className={`${viewLabels} ${delimiter}`}>{computeDelimiter(shipment)}</p>
                                    ) : (
                                        [
                                            ftpShipmentFieldErrors.delimiterErr ? (
                                                <Form.Control
                                                    className={errClass}
                                                    defaultValue={shipment.config?.delimiter}
                                                    onChange={(e) => {
                                                        handleChangeToConfig(e, 'delimiter');
                                                    }}
                                                    placeholder="; or ,"
                                                />
                                            ) : (
                                                <Form.Control
                                                    defaultValue={shipment.config?.delimiter}
                                                    onChange={(e) => {
                                                        handleChangeToConfig(e, 'delimiter');
                                                    }}
                                                    placeholder="; or ,"
                                                />
                                            ),
                                        ]
                                    )}
                                </Col>
                            </Form.Group>
                            {/* active */}
                            <Form.Group as={Col} className="mb-4" controlId="resourceUrl" sm={2}>
                                <Form.Label column sm={2} className={labels}>
                                    Active
                                </Form.Label>
                                <Col>
                                    {viewMode ? (
                                        <p style={{ backgroundColor: shipment?.isActive ? '#C6FFC1' : '' }} className={`${viewLabels} ${isActiveClass}`}>
                                            {shipment?.isActive ? 'Yes' : 'No'}
                                        </p>
                                    ) : (
                                        [
                                            shipment.isActive ? (
                                                <Form.Check defaultChecked type="checkbox" label="is active" onClick={handleIsActiveState} />
                                            ) : (
                                                <Form.Check type="checkbox" label="is active" onClick={handleIsActiveState} />
                                            ),
                                        ]
                                    )}
                                </Col>
                            </Form.Group>
                        </Row>
                    </div>
                    <hr />

                    {/*mapping*/}
                    <div className={mappingForm}>
                        <div className={integrationFormSubHeader}>
                            <h5>Shipment mapping</h5>
                        </div>

                        {/* trackTraceNumberField */}
                        <Form.Group as={Row} className={`mb-3`} controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                Track & trace number field
                            </Form.Label>
                            <Col sm={8}>
                                {viewMode ? (
                                    <Col className={`${viewLabels} ${mappingWrapper}`}>
                                        {shipment.config?.trackTraceNumberField !== '' ? shipment.config?.trackTraceNumberField : shipment.config?.mapping?.trackTraceNumberField}
                                    </Col>
                                ) : (
                                    [
                                        ftpShipmentFieldErrors.trackTraceNumberFieldErr ? (
                                            <Form.Control
                                                className={errClass}
                                                defaultValue={
                                                    shipment.config?.trackTraceNumberField !== '' ? shipment.config?.trackTraceNumberField : shipment.config?.mapping?.trackTraceNumberField
                                                }
                                                placeholder="trackTraceNumberField_field"
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'trackTraceNumberField');
                                                }}
                                            />
                                        ) : (
                                            <Form.Control
                                                defaultValue={
                                                    shipment.config?.trackTraceNumberField !== '' ? shipment.config?.trackTraceNumberField : shipment.config?.mapping?.trackTraceNumberField
                                                }
                                                placeholder="trackTraceNumberField_field"
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'trackTraceNumberField');
                                                }}
                                            />
                                        ),
                                    ]
                                )}
                            </Col>
                        </Form.Group>

                        {/* carrierField */}
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                Carrier field
                            </Form.Label>
                            <Col sm={8}>
                                {viewMode ? (
                                    <Col className={`${viewLabels} ${mappingWrapper}`}>
                                        {shipment.config?.carrierField !== '' ? shipment.config?.carrierField : shipment.config?.mapping?.carrierField}
                                    </Col>
                                ) : (
                                    [
                                        ftpShipmentFieldErrors.carrierFieldErr ? (
                                            <Form.Control
                                                className={errClass}
                                                defaultValue={shipment.config?.carrierField !== '' ? shipment.config?.carrierField : shipment.config?.mapping?.carrierField}
                                                placeholder="carrier_field"
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'carrierField');
                                                }}
                                            />
                                        ) : (
                                            <Form.Control
                                                defaultValue={shipment.config?.carrierField !== '' ? shipment.config?.carrierField : shipment.config?.mapping?.carrierField}
                                                placeholder="carrier_field"
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'carrierField');
                                                }}
                                            />
                                        ),
                                    ]
                                )}
                            </Col>
                        </Form.Group>

                        {/* identifierField */}
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                Identifier field
                            </Form.Label>
                            <Col sm={8}>
                                {viewMode ? (
                                    <Col className={`${viewLabels} ${mappingWrapper}`}>
                                        {shipment.config?.identifierField !== '' ? shipment.config?.identifierField : shipment.config?.mapping?.identifierField}
                                    </Col>
                                ) : (
                                    [
                                        ftpShipmentFieldErrors.identifierFieldErr ? (
                                            <Form.Control
                                                className={errClass}
                                                defaultValue={shipment.config?.identifierField !== '' ? shipment.config?.identifierField : shipment.config?.mapping?.identifierField}
                                                placeholder="identifier_field"
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'identifierField');
                                                }}
                                            />
                                        ) : (
                                            <Form.Control
                                                defaultValue={shipment.config?.identifierField !== '' ? shipment.config?.identifierField : shipment.config?.mapping?.identifierField}
                                                placeholder="identifier_field"
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'identifierField');
                                                }}
                                            />
                                        ),
                                    ]
                                )}
                            </Col>
                        </Form.Group>

                        {/* skuField*/}
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                SKU field
                            </Form.Label>
                            <Col sm={8}>
                                {viewMode ? (
                                    <Col className={`${viewLabels} ${mappingWrapper}`}>
                                        {shipment.config?.skuField !== '' ? shipment.config?.skuField : shipment.config?.mapping?.skuField}
                                    </Col>
                                ) : (
                                    [
                                        ftpShipmentFieldErrors.skuFieldErr ? (
                                            <Form.Control
                                                className={errClass}
                                                defaultValue={shipment.config?.skuField !== '' ? shipment.config?.skuField : shipment.config?.mapping?.skuField}
                                                placeholder="sku_field"
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'skuField');
                                                }}
                                            />
                                        ) : (
                                            <Form.Control
                                                defaultValue={shipment.config?.skuField !== '' ? shipment.config?.skuField : shipment.config?.mapping?.skuField}
                                                placeholder="sku_field"
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'skuField');
                                                }}
                                            />
                                        ),
                                    ]
                                )}
                            </Col>
                        </Form.Group>

                        {/* quantityField */}
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                Quantity field
                            </Form.Label>
                            <Col sm={8}>
                                {viewMode ? (
                                    <Col className={`${viewLabels} ${mappingWrapper}`}>
                                        {shipment.config?.quantityField !== '' ? shipment.config?.quantityField : shipment.config?.mapping?.quantityField}
                                    </Col>
                                ) : (
                                    [
                                        ftpShipmentFieldErrors.quantityFieldErr ? (
                                            <Form.Control
                                                className={errClass}
                                                defaultValue={shipment.config?.quantityField !== '' ? shipment.config?.quantityField : shipment.config?.mapping?.quantityField}
                                                placeholder="quantity_field"
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'quantityField');
                                                }}
                                            />
                                        ) : (
                                            <Form.Control
                                                defaultValue={shipment.config?.quantityField !== '' ? shipment.config?.quantityField : shipment.config?.mapping?.quantityField}
                                                placeholder="quantity_field"
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'quantityField');
                                                }}
                                            />
                                        ),
                                    ]
                                )}
                            </Col>
                        </Form.Group>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default FTPshipment;
