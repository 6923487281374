import axios from 'axios';

const accessToken = localStorage.getItem('authToken') as string;

const gatewayApiInstance = axios.create({
    baseURL: process.env.REACT_APP_GATEWAY_API_URL || 'https://staging.naduvi.io/api/gateway/',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
    },
});

export default gatewayApiInstance;
