import React, { useContext, useState } from 'react';
import {
    viewLabels,
    topSettings,
    labels,
    integrationForm,
    mappingForm,
    integrationFormSubHeader,
    mappingWrapper,
    delimiter,
    subFormTitle,
    dateInfo,
    dateStringClass,
    isActiveClass,
    generalSettingsClass,
} from '../integrationForm.module.scss';
import { Col, Form, Row } from 'react-bootstrap';
import { configMappingInterface, CreateIntegrationContext } from '../createIntegraton/createIntegrationContext';
import { IntegrationServiceContext } from '../integrationService/integrationServiceContext';
import { errClass } from './styles/formStyles.module.scss';

interface Props {
    viewMode: boolean;
}

const FTPorder: React.FC<Props> = ({ viewMode }: Props): JSX.Element => {
    const { integration, setIntegration, computeDelimiter, formatISOtoReadable, ftpOrderFieldErrors } = useContext(IntegrationServiceContext);
    const { config, setConfig } = useContext(CreateIntegrationContext);

    const [mapping, setMapping] = useState<configMappingInterface>({} as configMappingInterface);

    const order = integration;
    console.log(order);

    const handleChangeToMapping = (evt: any, value: string) => {
        setMapping({ ...mapping, [value]: evt.target.value });
        setConfig({ ...config, mapping });
        handleChangeToIntegrationObject();
    };

    const handleChangeToConfig = (evt: any, value: string) => {
        setConfig({ ...config, [value]: evt.target.value });
        handleChangeToIntegrationObject();
    };

    const handleChangeToIntegrationObject = () => {
        setIntegration({ ...order, config });
    };

    const handleIsActiveState = () => {
        if (order.isActive) {
            setIntegration({ ...order, isActive: false });
        } else {
            setIntegration({ ...order, isActive: true });
        }
    };

    return (
        <>
            <div>
                <div className={subFormTitle}>
                    <h4>{order.integrationName} Settings</h4>
                </div>
                <hr />

                <div className={dateInfo}>
                    <div>
                        Last update at:
                        <div className={dateStringClass}>{formatISOtoReadable(order.updatedAt)}</div>
                    </div>
                    <div>
                        Created at:
                        <div className={dateStringClass}> {formatISOtoReadable(order.createdAt)}</div>
                    </div>
                </div>

                <Form className={integrationForm}>
                    <div className={generalSettingsClass}>
                        {/* resource & delimiter */}
                        <Row className={topSettings}>
                            <Form.Group as={Col} className="mb-2" controlId="resourceUrl" sm={9}>
                                <Form.Label column sm={2} className={labels}>
                                    Url
                                </Form.Label>
                                <Col>
                                    {viewMode ? (
                                        <p className={viewLabels}>{order.config?.resource}</p>
                                    ) : (
                                        [
                                            ftpOrderFieldErrors.resourceErr ? (
                                                <Form.Control
                                                    className={errClass}
                                                    defaultValue={order.config?.resource}
                                                    onChange={(e) => {
                                                        handleChangeToConfig(e, 'resource');
                                                    }}
                                                    type="text"
                                                    placeholder="sftp://username:password@host/PriceInfo/PrijsLijst.csv"
                                                />
                                            ) : (
                                                <Form.Control
                                                    defaultValue={order.config?.resource}
                                                    onChange={(e) => {
                                                        handleChangeToConfig(e, 'resource');
                                                    }}
                                                    type="text"
                                                    placeholder="sftp://username:password@host/PriceInfo/PrijsLijst.csv"
                                                />
                                            ),
                                        ]
                                    )}
                                </Col>
                            </Form.Group>

                            {/* active */}
                            <Form.Group as={Col} className="mb-4" controlId="resourceUrl" sm={2}>
                                <Form.Label column sm={2} className={labels}>
                                    Active
                                </Form.Label>
                                <Col>
                                    {viewMode ? (
                                        <p style={{ backgroundColor: order?.isActive ? '#C6FFC1' : '' }} className={`${viewLabels} ${isActiveClass}`}>
                                            {order?.isActive ? 'Yes' : 'No'}
                                        </p>
                                    ) : (
                                        [
                                            order.isActive ? (
                                                <Form.Check defaultChecked type="checkbox" label="is active" onClick={handleIsActiveState} />
                                            ) : (
                                                <Form.Check type="checkbox" label="is active" onClick={handleIsActiveState} />
                                            ),
                                        ]
                                    )}
                                </Col>
                            </Form.Group>
                        </Row>
                    </div>
                    <hr />

                    {/*file fields*/}
                    <div className={mappingForm}>
                        <div className={integrationFormSubHeader}>
                            <h5>Order file fields</h5>
                        </div>

                        {/* header */}
                        <Form.Group as={Row} className={`mb-3`} controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                Header field
                            </Form.Label>

                            <Col sm={8}>
                                {viewMode ? (
                                    <Col className={`${viewLabels} ${mappingWrapper}`}>
                                        {order.config?.headerField !== '' ? order.config?.headerField : order.config?.mapping?.headerField}
                                    </Col>
                                ) : (
                                    [
                                        ftpOrderFieldErrors.headerFieldErr ? (
                                            <Form.Control
                                                className={errClass}
                                                defaultValue={order.config?.headerField !== '' ? order.config?.headerField : order.config?.mapping?.headerField}
                                                placeholder="order_id;order_date;sku;ean;price;quantity;delivery_company;delivery_name;delivery_address_line_1;delivery_address_line_2;delivery_postal_code;delivery_city;delivery_country;delivery_email;delivery_phone;billing_company;billing_name;billing_address_line_1;billing_address_line_2;billing_postal_code;billing_city;billing_country;billing_email;billing_phone;marketplace"
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'headerField');
                                                }}
                                            />
                                        ) : (
                                            <Form.Control
                                                defaultValue={order.config?.headerField !== '' ? order.config?.headerField : order.config?.mapping?.headerField}
                                                placeholder="order_id;order_date;sku;ean;price;quantity;delivery_company;delivery_name;delivery_address_line_1;delivery_address_line_2;delivery_postal_code;delivery_city;delivery_country;delivery_email;delivery_phone;billing_company;billing_name;billing_address_line_1;billing_address_line_2;billing_postal_code;billing_city;billing_country;billing_email;billing_phone;marketplace"
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'headerField');
                                                }}
                                            />
                                        ),
                                    ]
                                )}
                            </Col>
                        </Form.Group>

                        {/* Line field */}
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                Line field
                            </Form.Label>

                            <Col sm={8}>
                                {viewMode ? (
                                    <Col className={`${viewLabels} ${mappingWrapper}`}>{order.config?.lineField !== '' ? order.config?.lineField : order.config?.mapping?.lineField}</Col>
                                ) : (
                                    [
                                        ftpOrderFieldErrors.lineFieldErr ? (
                                            <Form.Control
                                                className={errClass}
                                                defaultValue={order.config?.lineField !== '' ? order.config?.lineField : order.config?.mapping?.lineField}
                                                placeholder="{order_id};{order_date};{product_sku};{product_ean};{product_price};{product_quantity};{shipping_company_name};{shipping_name};{shipping_address_line_1};{shipping_address_line_2};{shipping_postal};{shipping_city};{shipping_country_code};{email};{telephone};{billing_company_name};{billing_name};{billing_address_line_1};{billing_address_line_2};{billing_postal};{billing_city};{billing_country_code};{email};{telephone};{marketplace}"
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'lineField');
                                                }}
                                            />
                                        ) : (
                                            <Form.Control
                                                defaultValue={order.config?.lineField !== '' ? order.config?.lineField : order.config?.mapping?.lineField}
                                                placeholder="{order_id};{order_date};{product_sku};{product_ean};{product_price};{product_quantity};{shipping_company_name};{shipping_name};{shipping_address_line_1};{shipping_address_line_2};{shipping_postal};{shipping_city};{shipping_country_code};{email};{telephone};{billing_company_name};{billing_name};{billing_address_line_1};{billing_address_line_2};{billing_postal};{billing_city};{billing_country_code};{email};{telephone};{marketplace}"
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'lineField');
                                                }}
                                            />
                                        ),
                                    ]
                                )}
                            </Col>
                        </Form.Group>

                        {/* footer */}
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                Footer field
                            </Form.Label>
                            <Col sm={8}>
                                {viewMode ? (
                                    <Col className={`${viewLabels} ${mappingWrapper}`}>
                                        {order.config?.footerField !== '' ? order.config?.footerField : order.config?.mapping?.footerField}
                                    </Col>
                                ) : (
                                    [
                                        ftpOrderFieldErrors.footerFieldErr ? (
                                            <Form.Control
                                                className={errClass}
                                                defaultValue={order.config?.footerField !== '' ? order.config?.footerField : order.config?.mapping?.footerField}
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'footerField');
                                                }}
                                            />
                                        ) : (
                                            <Form.Control
                                                defaultValue={order.config?.footerField !== '' ? order.config?.footerField : order.config?.mapping?.footerField}
                                                placeholder=""
                                                onChange={(e) => {
                                                    handleChangeToConfig(e, 'footerField');
                                                }}
                                            />
                                        ),
                                    ]
                                )}
                            </Col>
                        </Form.Group>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default FTPorder;
