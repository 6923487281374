import React, { useContext, useEffect } from 'react';
import { IntegrationControls } from '../../integrationsControls/integrationsControls';
import { settingsWrapper } from '../../integrationForm.module.scss';
import { IntegrationServiceContext } from '../../integrationService/integrationServiceContext';
import { integrationInterface } from '../../../suppliersOverview/suppliersOverviewContext';
import FTPshipment from '../../platformSpecificIntegrations/FTPshipment';
import SupplierHeader from '../../../supplier/supplierHeader/SupplierHeader';
import { divider } from '../../../../pages/supplierPage/supplierPage.module.scss';

const Shipment: React.FC = () => {
    const { viewMode, setViewMode, getIntegration } = useContext(IntegrationServiceContext);
    const supplierName = localStorage.getItem('supplierName' as string);
    let shipment: Record<any, any> = {} as integrationInterface;

    // update stock state
    useEffect(() => {
        if (!shipment) {
            shipment = getIntegration(`${supplierName}`, 'fetch.shipment');
        }
    }, [shipment]);

    return (
        <>
            <div className="integrationFormWrapper">
                <SupplierHeader />
                <div className={divider}>
                    <hr />
                </div>

                <div className={settingsWrapper}>
                    {/* settings*/}
                    {<FTPshipment viewMode={viewMode} />}
                    <hr />
                </div>
            </div>
            <IntegrationControls viewMode={viewMode} setViewMode={setViewMode} integrationName={'FTPshipment'} />
        </>
    );
};

export default Shipment;
