import React, { useContext, useEffect } from 'react';
import { IntegrationsOverviewContext } from '../../components/integrationsOverview/integrationsOverviewContext';
import { SupplierIntegrationsOverview } from '../../components/supplier/supplierIntegrationsOverview/supplierIntegrationsOverview';
import SupplierHeader from '../../components/supplier/supplierHeader/SupplierHeader';
import { divider } from '../supplierPage/supplierPage.module.scss';

export const SupplierIntegrationsOverviewPage: React.FC = () => {
    const { fetchIntegrations, integrations } = useContext(IntegrationsOverviewContext);
    const supplierIntegrations = JSON.parse(localStorage.getItem('supplierIntegrations') as string);

    useEffect(() => {
        if (Object.keys(integrations).length === 0) {
            fetchIntegrations();
        }
    }, [supplierIntegrations, integrations]);

    return (
        <>
            {supplierIntegrations !== undefined && (
                <>
                    <SupplierHeader />
                    <div className={divider}>
                        <hr />
                    </div>
                    <SupplierIntegrationsOverview />
                </>
            )}
        </>
    );
};
