import React, { useContext, useEffect } from 'react';
import Stock from '../../../components/integrations/viewEditIntegrationsTypes/stock/stock';
import { StockProvider } from '../../../components/integrations/viewEditIntegrationsTypes/stock/stockContext';
import { IntegrationServiceContext } from '../../../components/integrations/integrationService/integrationServiceContext';

export const StockPage: React.FC = () => {
    const supplierName = localStorage.getItem('supplierName' as string);
    const { integration, viewMode, setViewMode, getIntegration } = useContext(IntegrationServiceContext);

    useEffect(() => {
        if (Object.keys(integration).length === 0) {
            getIntegration(`${supplierName}`, 'fetch.stock');
        }
    }, [integration]);

    return (
        <>
            <Stock />
        </>
    );
};
