import React, { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { SupplierContext } from '../../supplier/supplier/supplierContext';
import { goButton, cancelButton } from './../modals/modal.module.scss';
import { IntegrationServiceContext } from '../../integrations/integrationService/integrationServiceContext';

interface Props {
    show: boolean;
    setShow: (state: any) => void;
}

const EditIntegration: React.FC = () => {
    const navigate = useNavigate();
    const { setViewMode } = useContext(IntegrationServiceContext);
    const { getSupplier, supplierJettiId, supplierName } = useContext(SupplierContext);
    const supplierNumber = localStorage.getItem('supplierNumber' as string);
    const { editIntegration } = useContext(IntegrationServiceContext);
    const [show, setShow] = useState(true);

    const handleCancelEdit = () => setShow(false);

    const handleDone = () => {
        setShow(false);

        editIntegration();
        getSupplier(supplierName);

        setTimeout(() => {
            window.location.reload();
        }, 200);
    };

    const changes = ['change1', 'change2'];

    return (
        <>
            <Modal centered show={show} onHide={handleCancelEdit} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>Are you sure you want to edit this integration?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    These are the changes that will be made to this integration: -{/*{changes.map((change, i) => {*/}
                    {/*    return <div key={i}>{change}</div>;*/}
                    {/*})}*/}
                </Modal.Body>
                <Modal.Footer>
                    <Button className={cancelButton} onClick={handleCancelEdit}>
                        No, I&apos;m not sure
                    </Button>
                    <Button className={goButton} onClick={handleDone}>
                        Yes, I&apos;m sure
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditIntegration;
