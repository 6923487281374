import React, { useContext, useEffect } from 'react';
import { integrationFormHeader, settingsWrapper, subFormTitle } from '../../integrationForm.module.scss';
import FTPstock from '../../platformSpecificIntegrations/FTPstock';
import { IntegrationControls } from '../../integrationsControls/integrationsControls';
import { useNavigate } from 'react-router-dom';
import { IntegrationServiceContext } from '../../integrationService/integrationServiceContext';
import { integrationInterface } from '../../../suppliersOverview/suppliersOverviewContext';
import SupplierHeader from '../../../supplier/supplierHeader/SupplierHeader';
import { divider } from '../../../../pages/supplierPage/supplierPage.module.scss';

const Stock: React.FC = () => {
    const { viewMode, setViewMode, getIntegration } = useContext(IntegrationServiceContext);
    const supplierName = localStorage.getItem('supplierName' as string);
    let stock = {} as integrationInterface;

    // update stock state
    useEffect(() => {
        if (!stock) {
            getIntegration(`${supplierName}`, 'fetch.stock');
        }
    }, []);

    return (
        <>
            <div className="integrationFormWrapper">
                <SupplierHeader />
                <div className={divider}>
                    <hr />
                </div>

                <div className={settingsWrapper}>
                    {/* FTP stock settings*/}
                    <FTPstock viewMode={viewMode} />

                    <hr />
                </div>
            </div>
            <IntegrationControls viewMode={viewMode} setViewMode={setViewMode} integrationName={'FTPstock'} />
        </>
    );
};

export default Stock;
