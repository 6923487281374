import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import gatewayApiInstance from '../../../services/gatewayAPI/gatewayApiInstance/gatewayApiInstance';
import { integrationInterface, suppliersIntegrationsInterface } from '../../suppliersOverview/suppliersOverviewContext';
import { ellaSupplierInfoInterface, supplierContractInterface, supplierInfoInterface } from './supplierInterfaces';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

// interfaces
export interface supplierContextInterface {
    integrationsNames: string[];
    supplierIntegrations: integrationInterface[];
    activeContract: supplierContractInterface;
    // integrations
    stockIntegration: integrationInterface;
    priceIntegration: integrationInterface;
    stockPriceIntegration: integrationInterface;
    shipmentIntegration: integrationInterface;
    orderIntegration: integrationInterface;
    getSupplier: (supplierName: string) => void;
    noExistingIntegrations: boolean;
    supplierInfo: ellaSupplierInfoInterface;
    supplierName: string;
    setSupplierName: (state: string) => void;
    supplierJettiId: number;
    setSupplierJettiId: (state: number) => void;
    fetchSupplierInfo: () => void;
    fetchSupplierIntegrations: () => void;
    fetchSupplierContracts: () => void;
    contracts: supplierContractInterface[];
}

export const SupplierContext = React.createContext<supplierContextInterface>({} as supplierContextInterface);

export const SupplierProvider: React.FC = ({ children }) => {
    // initialize states
    const [integrationsNames, setIntegrationsNames] = useState<string[]>(['']);
    const [noExistingIntegrations, setNoExistingIntegrations] = useState(false);
    const [supplierInfo, setSupplierInfo] = useState<ellaSupplierInfoInterface>({} as ellaSupplierInfoInterface);
    const [supplierName, setSupplierName] = useState('');
    const [supplierJettiId, setSupplierJettiId] = useState(0);
    const [supplierIntegrations, setSupplierIntegrations] = useState<integrationInterface[]>({} as integrationInterface[]);
    const [activeContract, setActiveContract] = useState<supplierContractInterface>({} as supplierContractInterface);
    const [contracts, setContracts] = useState<supplierContractInterface[]>({} as supplierContractInterface[]);

    // initialize viewEditIntegrationsTypes states
    const [stockIntegration, setStockIntegration] = useState<integrationInterface>({} as integrationInterface);
    const [priceIntegration, setPriceIntegration] = useState<integrationInterface>({} as integrationInterface);
    const [stockPriceIntegration, setStockPriceIntegration] = useState<integrationInterface>({} as integrationInterface);
    const [shipmentIntegration, setShipmentIntegration] = useState<integrationInterface>({} as integrationInterface);
    const [orderIntegration, setOrderIntegration] = useState<integrationInterface>({} as integrationInterface);

    const computeActiveContract = (contracts: supplierContractInterface[]) => {
        for (let i = 0; i < contracts.length; i++) {
            if (contracts[i].current) {
                setActiveContract(contracts[i]);
            }
        }

        // for (const value of Object.values(contracts)) {
        //     console.log(value);
        //     if (value.current) {
        //         setActiveContract(value);
        //     }
        // }

        console.log(activeContract);
    };

    const fetchSupplierIntegrations = async () => {
        const name = localStorage.getItem('supplierName' as string);

        try {
            const response = await gatewayApiInstance.get(`/suppliers-integrations/${name}`);
            if (response.status === 200) {
                // localStorage.setItem('supplierIntegrations', JSON.stringify(tempIntegrations));
                // const supplierIntegrations = JSON.parse(localStorage.getItem('supplierIntegrations') as string);

                setIntegrationsNames(response.data[0].integrationsNames);
                const tempIntegrationNames = response.data[0].integrationsNames;
                sessionStorage.setItem('integrationsNames', JSON.stringify(tempIntegrationNames));
                localStorage.setItem('integrationsNames', JSON.stringify(tempIntegrationNames));
            } else {
                setNoExistingIntegrations(true);
                console.log(response);
            }
        } catch (e) {
            console.log(e);
        }
    };

    // Fetch a supplier object from ella
    const fetchSupplierInfo = async () => {
        const supplierNumber = localStorage.getItem('supplierNumber' as string);

        // const tempSupplierInformation = supplierInfoMock;
        // sessionStorage.setItem('supplierInformation', JSON.stringify(tempSupplierInformation));

        const response = await gatewayApiInstance.get(`/suppliers/ella/${supplierNumber}`);
        if (response.status === 200) {
            console.log(response.data);
            setSupplierInfo(response.data);

            const tempSupplierInfo = response.data;
            localStorage.setItem('supplierInfo', JSON.stringify(tempSupplierInfo));
        } else {
            console.log(response);
        }
    };

    // Fetch the supplier contracts from ella
    const fetchSupplierContracts = async () => {
        const supplierNumber = localStorage.getItem('supplierNumber' as string);

        const response = await gatewayApiInstance.get(`/suppliers/ella/${supplierNumber}/contracts`);
        if (response.status === 200) {
            console.log(response.data);
            setContracts(response.data);

            const tempSupplierContracts = response.data;
            localStorage.setItem('supplierContracts', JSON.stringify(tempSupplierContracts));

            computeActiveContract(response.data);
        } else {
            console.log(response);
        }
    };

    const getSupplier = (supplierName: string) => {
        console.log(supplierName);
        let name = 'CozyHouse';
        fetchSupplierInfo();
        fetchSupplierIntegrations();
    };

    return (
        <SupplierContext.Provider
            value={{
                integrationsNames,
                supplierIntegrations,
                activeContract,
                // viewEditIntegrationsTypes
                stockIntegration,
                priceIntegration,
                stockPriceIntegration,
                shipmentIntegration,
                orderIntegration,
                getSupplier,
                noExistingIntegrations,
                supplierInfo,
                supplierName,
                setSupplierName,
                supplierJettiId,
                setSupplierJettiId,
                fetchSupplierInfo,
                fetchSupplierIntegrations,
                fetchSupplierContracts,
                contracts,
            }}
        >
            {children}
        </SupplierContext.Provider>
    );
};
