import React, { useContext, useEffect, useState } from 'react';
import { topSettings, labels, integrationForm, mappingForm, integrationFormSubHeader, subFormTitle } from './../../integrationForm.module.scss';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { CreateIntegrationContext } from '../createIntegrationContext';
import shipment from '../../viewEditIntegrationsTypes/shipment/shipment';

export interface FTPshipmentConfigMappingInterface {
    identifierField: string;
    trackTraceNumberField: string;
    carrierField: string;
    quantityField: string;
}

const FTPshipment: React.FC = (): JSX.Element => {
    const { config, setConfig, integrationInput, setIntegrationInput } = useContext(CreateIntegrationContext);
    const [fields, setFields] = useState<string[]>([]);
    const [mapping, setMapping] = useState<FTPshipmentConfigMappingInterface>({
        identifierField: '',
        trackTraceNumberField: '',
        carrierField: '',
        quantityField: '',
    } as FTPshipmentConfigMappingInterface);

    const handleChangeInput = (i: string | number, e: React.ChangeEvent) => {
        const descriptions = [...fields];
        // @ts-ignore
        descriptions[i] = e.target.value;
        setFields(descriptions);
        handleChangeToConfig(descriptions, 'stockFieldAcceptableDescriptions');
    };

    const handleAddFieldClick = (description: string) => {
        setFields([...fields, description]);
    };

    const handleSubtractionFieldClick = (i: number) => {
        const descriptions = [...fields];
        descriptions.splice(i, 1);
        setFields([...descriptions]);
    };

    // handle change to states
    const handleChangeToMapping = (evt: any, value: string) => {
        setMapping({ ...mapping, [value]: evt.target.value });
        setConfig({ ...config, mapping });
        handleChangeToIntegrationObject();
    };

    const handleChangeToConfig = (evt: any, value: string) => {
        setConfig({ ...config, [value]: evt.target.value });
        handleChangeToIntegrationObject();
    };

    const handleChangeToIntegrationObject = () => {
        setIntegrationInput({ ...integrationInput, config });
    };

    return (
        <>
            <div>
                <div className={subFormTitle}>
                    <h4>FTP shipment settings</h4>
                </div>
                <hr />
                <Form className={integrationForm}>
                    {/* resource & delimiter */}
                    <Row className={topSettings}>
                        <Form.Group as={Col} className="mb-2" controlId="resourceUrl" sm={9}>
                            <Form.Label column sm={2} className={labels}>
                                Url
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    onChange={(e) => {
                                        handleChangeToConfig(e, 'resource');
                                    }}
                                    placeholder="sftp://username:password@host/leveringen/PrijsLijst.csv"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-4" controlId="resourceUrl" sm={2}>
                            <Form.Label column sm={2} className={labels}>
                                Delimiter
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    onChange={(e) => {
                                        handleChangeToConfig(e, 'delimiter');
                                    }}
                                    placeholder="; or ,"
                                />
                            </Col>
                        </Form.Group>
                    </Row>

                    <hr />

                    {/*mapping*/}
                    <div className={mappingForm}>
                        <div className={integrationFormSubHeader}>
                            <h5>File fields mapping</h5>
                        </div>

                        {/* Identifier */}
                        <Form.Group as={Row} className={`mb-3`} controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                Identifier field
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control
                                    placeholder="identifier_field"
                                    onChange={(e) => {
                                        handleChangeToConfig(e, 'identifierField');
                                    }}
                                />
                            </Col>
                        </Form.Group>

                        {/* trackTraceNumberField */}
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                Track & Trace number field
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control
                                    placeholder="trackTraceNumber_field"
                                    onChange={(e) => {
                                        handleChangeToConfig(e, 'trackTraceNumberField');
                                    }}
                                />
                            </Col>
                        </Form.Group>

                        {/* carrierField */}
                        <Form.Group as={Row} className={`mb-3`} controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                Carrier field
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control
                                    placeholder="carrier_field"
                                    onChange={(e) => {
                                        handleChangeToConfig(e, 'carrierField');
                                    }}
                                />
                            </Col>
                        </Form.Group>

                        {/* quantityField */}
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                Quantity field
                            </Form.Label>

                            <Col sm={8}>
                                <Form.Control
                                    placeholder="quantity_field"
                                    onChange={(e) => {
                                        handleChangeToConfig(e, 'quantityField');
                                    }}
                                />
                            </Col>
                        </Form.Group>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default FTPshipment;
