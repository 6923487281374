import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { CreateIntegrationContext } from '../createIntegrationContext';
import { topSettings, labels, integrationForm, mappingForm, integrationFormSubHeader, subFormTitle } from './../../integrationForm.module.scss';

export interface FTPorderConfigMappingInterface {
    externalOrderIdentifierField: string;
    headerField: string;
    lineField: string;
    footerField: string;
}

const FTPorder: React.FC = (): JSX.Element => {
    const { config, setConfig, integrationInput, setIntegrationInput } = useContext(CreateIntegrationContext);
    const [fields, setFields] = useState<string[]>([]);
    const [mapping, setMapping] = useState<FTPorderConfigMappingInterface>({
        externalOrderIdentifierField: '',
        headerField: '',
        lineField: '',
        footerField: '',
    } as FTPorderConfigMappingInterface);

    // handle change to states
    const handleChangeToMapping = (evt: any, value: string) => {
        setMapping({ ...mapping, [value]: evt.target.value });
        setConfig({ ...config, mapping });
        handleChangeToIntegrationObject();
    };

    const handleChangeToConfig = (evt: any, value: string) => {
        setConfig({ ...config, [value]: evt.target.value });
        handleChangeToIntegrationObject();
    };

    const handleChangeToIntegrationObject = () => {
        setIntegrationInput({ ...integrationInput, config });
    };

    return (
        <>
            <div>
                <div className={subFormTitle}>
                    <h4>FTP Order settings</h4>
                </div>
                <hr />
                <Form className={integrationForm}>
                    {/* resource & delimiter */}
                    <Row className={topSettings}>
                        <Form.Group as={Col} className="mb-2" controlId="resourceUrl" sm={9}>
                            <Form.Label column sm={2} className={labels}>
                                Url
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    onChange={(e) => {
                                        handleChangeToConfig(e, 'resource');
                                    }}
                                    placeholder="sftp://username:password@host/PriceInfo/PrijsLijst.csv"
                                />
                            </Col>
                        </Form.Group>
                    </Row>
                    <hr />

                    {/*mapping*/}
                    <div className={mappingForm}>
                        <div className={integrationFormSubHeader}>
                            <h5>File fields mapping</h5>
                        </div>

                        {/* externalOrderIdentifier */}
                        <Form.Group as={Row} className={`mb-3`} controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                External order identifier field
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control
                                    placeholder="external_order_identifier_field"
                                    onChange={(e) => {
                                        handleChangeToConfig(e, 'externalOrderIdentifierField');
                                    }}
                                />
                            </Col>
                        </Form.Group>

                        {/* header */}
                        <Form.Group as={Row} className={`mb-3`} controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                Header field
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control
                                    placeholder="order_id;order_date;sku;ean;price;quantity;delivery_company;delivery_name;delivery_address_line_1;delivery_address_line_2;delivery_postal_code;delivery_city;delivery_country;delivery_email;delivery_phone;billing_company;billing_name;billing_address_line_1;billing_address_line_2;billing_postal_code;billing_city;billing_country;billing_email;billing_phone;marketplace"
                                    onChange={(e) => {
                                        handleChangeToConfig(e, 'headerField');
                                    }}
                                />
                            </Col>
                        </Form.Group>

                        {/* line */}
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                Line field
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control
                                    placeholder="{order_id};{order_date};{product_sku};{product_ean};{product_price};{product_quantity};{shipping_company_name};{shipping_name};{shipping_address_line_1};{shipping_address_line_2};{shipping_postal};{shipping_city};{shipping_country_code};{email};{telephone};{billing_company_name};{billing_name};{billing_address_line_1};{billing_address_line_2};{billing_postal};{billing_city};{billing_country_code};{email};{telephone};{marketplace}"
                                    onChange={(e) => {
                                        handleChangeToConfig(e, 'lineField');
                                    }}
                                />
                            </Col>
                        </Form.Group>

                        {/* footer */}
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={4}>
                                Footer field
                            </Form.Label>

                            <Col sm={8}>
                                <Form.Control
                                    placeholder="footer_field"
                                    onChange={(e) => {
                                        handleChangeToConfig(e, 'footerField');
                                    }}
                                />
                            </Col>
                        </Form.Group>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default FTPorder;
