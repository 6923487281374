import React, { useEffect, useState } from 'react';
import gatewayApiInstance from '../../services/gatewayAPI/gatewayApiInstance/gatewayApiInstance';

// interfaces
interface IntegrationsOverviewInterface {
    fetchIntegrations: () => void;
    integrations: Record<any, any>[];
}

// initialize the context
export const IntegrationsOverviewContext = React.createContext<IntegrationsOverviewInterface>({} as IntegrationsOverviewInterface);

export const IntegrationsOverviewProvider: React.FC = ({ children }) => {
    const [integrations, setIntegrations] = useState([]);

    const fetchIntegrations = async () => {
        console.log('response.data');

        try {
            const response = await gatewayApiInstance.get(`/suppliers-integrations`);
            if (response.status === 200) {
                const tempIntegrations = response.data;

                setIntegrations(tempIntegrations);
                localStorage.setItem('supplierIntegrations', JSON.stringify(tempIntegrations));
            } else {
                console.log(response);
            }
        } catch (e) {
            console.log(e);
        }
    };

    return <IntegrationsOverviewContext.Provider value={{ fetchIntegrations, integrations }}>{children}</IntegrationsOverviewContext.Provider>;
};
