import React, { useContext, useEffect, useState } from 'react';
import { controlButtonsWrapper, cancelButton } from '../integrationForm.module.scss';
import { Button } from 'react-bootstrap';
import AreYouSureModal from '../../uiComponents/modals/areYouSureModal';
import { IntegrationServiceContext } from '../integrationService/integrationServiceContext';
import EditIntegration from '../../uiComponents/modals/editIntegration';
import { FormValidationServiceContext } from './formValidationServiceContext';
import { ftpOrderFieldErrorsInitialState, ftpShipmentFieldErrorsInitialState, ftpStockFieldErrorsInitialState } from '../integrationService/integrationServiceInterfaces';

interface Props {
    viewMode: boolean;
    setViewMode: (state: boolean) => void;
    integrationName: string;
}

export const IntegrationControls: React.FC<Props> = ({ viewMode, setViewMode, integrationName }) => {
    const { integration, ftpStockFieldErrors, setFtpStockFieldErrors, config, setFtpShipmentFieldErrors, setFtpOrderFieldErrors } = useContext(IntegrationServiceContext);
    const { validateFormMainFunction } = useContext(FormValidationServiceContext);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showConformationEdit, setShowConformationEdit] = useState(false);

    const handleSubmit = () => {
        const result = validateFormMainFunction(config, integrationName);

        // if result === true, there were errors found
        if (result) {
            console.log('There were errors in one or more fields!');
            console.log(ftpStockFieldErrors);
        } else {
            setShowConformationEdit(true);
            resetFieldErrors();
        }
    };

    const handleCancelEdit = () => {
        setViewMode(true);
        resetFieldErrors();
    };

    const resetFieldErrors = () => {
        setFtpStockFieldErrors({ ...ftpStockFieldErrorsInitialState });
        setFtpShipmentFieldErrors({ ...ftpShipmentFieldErrorsInitialState });
        setFtpOrderFieldErrors({ ...ftpOrderFieldErrorsInitialState });
    };

    const handleDelete = () => {
        setShowDeleteModal(true);
    };

    const changeViewMode = () => {
        if (!viewMode) {
            setViewMode(true);
        } else {
            setViewMode(false);
        }
    };

    useEffect(() => {
        setViewMode(true);
    }, []);

    return (
        <>
            <div className={controlButtonsWrapper}>
                {viewMode ? (
                    <Button
                        variant="primary"
                        type="submit"
                        onClick={() => {
                            changeViewMode();
                        }}
                    >
                        Edit
                    </Button>
                ) : (
                    <Button variant="primary" type="submit" onClick={() => handleSubmit()}>
                        Apply changes
                    </Button>
                )}
                {!viewMode && (
                    <Button className={cancelButton} type="submit" onClick={() => handleCancelEdit()}>
                        Cancel
                    </Button>
                )}

                <Button type="submit" onClick={() => handleDelete()}>
                    Delete
                </Button>
            </div>
            <AreYouSureModal show={showDeleteModal} setShow={setShowDeleteModal} />
            {showConformationEdit && <EditIntegration />}
        </>
    );
};
