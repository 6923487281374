import React, { useContext, useEffect } from 'react';
import SupplierInfo from '../../components/supplier/supplierInfo/supplierInfo';
import { headerWrapper, supplierPageWrapper, contentWrapper, infoWrapper, supplierWrapper, divider } from './supplierPage.module.scss';
import SupplierHeader from '../../components/supplier/supplierHeader/SupplierHeader';
import { SupplierContext } from '../../components/supplier/supplier/supplierContext';

export const SupplierPage: React.FC = () => {
    const { fetchSupplierIntegrations, fetchSupplierInfo, fetchSupplierContracts } = useContext(SupplierContext);

    useEffect(() => {
        fetchSupplierInfo();
        fetchSupplierIntegrations();
        fetchSupplierContracts();
    }, []);

    return (
        <>
            {}
            <div className={supplierPageWrapper}>
                <div className={headerWrapper}>
                    <SupplierHeader />
                </div>
                <div className={divider}>
                    <hr />
                </div>

                <div className={infoWrapper}>
                    <SupplierInfo />
                </div>
            </div>
        </>
    );
};
