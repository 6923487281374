import React, { useEffect } from 'react';
import Login from 'frontend-core/src/pages/loginPage/LoginPage';
import { loginWrapper } from './login.module.scss';

export const LoginPage: React.FC = () => {
    localStorage.removeItem('username');

    return (
        <>
            <div className={loginWrapper}>
                <Login formHeader={'Login'} landingPage={'/overview'} />
            </div>
        </>
    );
};
