import React, { useContext, useEffect } from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { GatewayContext } from 'frontend-core/src/context/gateway-context';
import { Navigate } from 'react-router-dom';

// pages
import NotFound from './components/utils/notFound/notFound';
import { SupplierPage } from './pages/supplierPage/supplierPage';
import { StockPage } from './pages/integrationTypesPages/stockPage/stockPage';
import { EllaSupplierOverviewPage } from './pages/ellaSupplierOverviewPage/ellaSuppliersOverviewPage';
import { LoginPage } from './pages/loginPage/loginPage';
import { CreateIntegrationPage } from './pages/integrationTypesPages/createIntegrationPage/createIntegrationPage';
import { ShipmentPage } from './pages/integrationTypesPages/shipmentPage/ShipmentPage';
import { OrderPage } from './pages/integrationTypesPages/orderPage/orderPage';
import { AboutPage } from './pages/aboutPage/aboutPage';
import { IntegrationsOverviewPage } from './pages/integrationsOverviewPage/integrationsOverviewPage';
import { ContractsOverviewPage } from './pages/contractsOverviewPage/ContractsOverviewPage';
import { SupplierIntegrationsOverviewPage } from './pages/supplierIntegrationsOverview/supplierIntegrationsOverviewPage';
import { LogoutPage } from './pages/logoutPage/logoutPage';

function App(): JSX.Element {
    const { checkIfTokenIsNotExpired } = useContext(GatewayContext);

    // check if the user is logged in
    function RequireAuth({ children }: { children: JSX.Element }) {
        if (checkIfTokenIsNotExpired()) {
            return children;
        }
        return <Navigate to="/login" />;
    }

    return (
        <Container className="App">
            {/*<Header />*/}
            <main className="main">
                <Routes>
                    {/**/}
                    {/**/}
                    {/* GENERAL */}
                    <Route path="/" element={<LoginPage />} />
                    <Route path="login" element={<LoginPage />} />
                    <Route path="logout" element={<LogoutPage />} />
                    <Route
                        path="about"
                        element={
                            <RequireAuth>
                                <AboutPage />
                            </RequireAuth>
                        }
                    />
                    {/**/}
                    {/**/}
                    {/* SUPPLIERS */}
                    {/* supplier overview*/}
                    <Route
                        path="overview"
                        element={
                            <RequireAuth>
                                <EllaSupplierOverviewPage />
                            </RequireAuth>
                        }
                    />
                    {/* supplier detail */}
                    <Route
                        path="supplier/:supplierJettiId"
                        element={
                            <RequireAuth>
                                <SupplierPage />
                            </RequireAuth>
                        }
                    />
                    {/* suppliers integrations */}
                    <Route
                        path="supplier/:supplierJettiId/integrations"
                        element={
                            <RequireAuth>
                                <SupplierIntegrationsOverviewPage />
                            </RequireAuth>
                        }
                    />
                    {/* supplier create integration */}
                    <Route
                        path="supplier/:supplierJettiId/create-integration"
                        element={
                            <RequireAuth>
                                <CreateIntegrationPage showSupplierHeader={true} />
                            </RequireAuth>
                        }
                    />
                    {/*view/edit stock integration*/}
                    <Route
                        path="supplier/:supplierJettiId/stock"
                        element={
                            <RequireAuth>
                                <StockPage />
                            </RequireAuth>
                        }
                    />
                    {/* view/edit shipment integration */}
                    <Route
                        path="supplier/:supplierJettiId/shipment"
                        element={
                            <RequireAuth>
                                <ShipmentPage />
                            </RequireAuth>
                        }
                    />
                    {/*view/edit order integration*/}
                    <Route
                        path="supplier/:supplierJettiId/order"
                        element={
                            <RequireAuth>
                                <OrderPage />
                            </RequireAuth>
                        }
                    />
                    {/* supplier contracts overview */}
                    <Route
                        path="supplier/:supplierJettiId/contracts"
                        element={
                            <RequireAuth>
                                <ContractsOverviewPage />
                            </RequireAuth>
                        }
                    />
                    {/* supplier contract details */}
                    {/*<Route*/}
                    {/*    path=":supplierJettiId/contract"*/}
                    {/*    element={*/}
                    {/*        <RequireAuth>*/}
                    {/*            <ContractPage />*/}
                    {/*        </RequireAuth>*/}
                    {/*    }*/}
                    {/*/>*/}
                    {/**/}
                    {/**/}
                    {/* INTEGRATIONS */}
                    {/* integrations overview */}
                    <Route
                        path="supplier/integrations"
                        element={
                            <RequireAuth>
                                <IntegrationsOverviewPage />
                            </RequireAuth>
                        }
                    />
                    {/* create integration */}
                    <Route
                        path="supplier/integrations/create-integration"
                        element={
                            <RequireAuth>
                                <CreateIntegrationPage showSupplierHeader={false} />
                            </RequireAuth>
                        }
                    />

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </main>
        </Container>
    );
}

export default App;
