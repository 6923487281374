import React, { useContext, useEffect } from 'react';
import Order from '../../../components/integrations/viewEditIntegrationsTypes/order/order';
import { IntegrationServiceContext } from '../../../components/integrations/integrationService/integrationServiceContext';

export const OrderPage: React.FC = () => {
    const supplierName = localStorage.getItem('supplierName' as string);
    const { integration, getIntegration } = useContext(IntegrationServiceContext);

    useEffect(() => {
        if (Object.keys(integration).length === 0) {
            getIntegration(`${supplierName}`, 'create.order');
        }
    }, [integration]);

    return (
        <>
            <Order />
        </>
    );
};
