import React, { useState } from 'react';
import { integrationInterface } from '../../../suppliersOverview/suppliersOverviewContext';

// interfaces
export interface OrderContextInterface {
    orderObject: integrationInterface;
    orderConfig: orderConfigInterface;
    setOrderObject: (state: any) => void;
    setOrderConfig: (state: any) => void;
}

export interface orderConfigInterface {
    resource: string;
    delimiter: string;
    header: string;
    line: string;
    footer?: string;
}

export interface orderIntegrationInterface extends integrationInterface {
    config: {
        resource: string;
        delimiter: string;
        header: string;
        line: string;
        footer?: string;
    };
}

export const OrderContext = React.createContext<OrderContextInterface>({} as OrderContextInterface);

export const OrderProvider: React.FC = ({ children }) => {
    const [orderConfig, setOrderConfig] = useState({} as orderConfigInterface);
    const [orderObject, setOrderObject] = useState({} as orderIntegrationInterface);

    return (
        <OrderContext.Provider
            value={{
                orderObject,
                orderConfig,
                setOrderObject,
                setOrderConfig,
            }}
        >
            {children}
        </OrderContext.Provider>
    );
};
