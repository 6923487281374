import React, { useContext, useEffect } from 'react';
import { ContractsOverview } from '../../components/supplier/contractsOverview/contractsOverview';
import { SupplierContext } from '../../components/supplier/supplier/supplierContext';
import SupplierHeader from '../../components/supplier/supplierHeader/SupplierHeader';
import { divider } from '../supplierPage/supplierPage.module.scss';

export const ContractsOverviewPage: React.FC = () => {
    const { contracts, fetchSupplierContracts } = useContext(SupplierContext);
    const supplierContracts = JSON.parse(sessionStorage.getItem('supplierContracts') as string);

    useEffect(() => {
        if (Object.keys(contracts).length === 0) {
            fetchSupplierContracts();
        }
    }, [supplierContracts, contracts]);

    return (
        <>
            {contracts !== undefined && (
                <>
                    <SupplierHeader />
                    <div className={divider}>
                        <hr />
                    </div>
                    <ContractsOverview />
                </>
            )}
        </>
    );
};
