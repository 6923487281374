import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { infoModal } from './modal.module.scss';

interface Props {
    show: boolean;
    setShow: (state: any) => void;
    info: string;
    timeout: boolean;
}

const InfoModal: React.FC<Props> = ({ show, setShow, info, timeout }) => {
    const handleClose = () => setShow(false);

    const timer = () => {
        setTimeout(() => {
            setShow(false);
        }, 2000);
    };

    if (timeout) {
        timer();
    }

    return (
        <>
            <Modal dialogClassName={infoModal} show={show} onHide={timeout ? '' : handleClose}>
                <Modal.Body>{info}</Modal.Body>
            </Modal>
        </>
    );
};

export default InfoModal;
