import React, { useState } from 'react';
import { integrationInterface } from '../../../suppliersOverview/suppliersOverviewContext';

// interfaces
export interface ShipmentContext {
    shipmentObject: integrationInterface;
    shipmentConfig: shipmentConfigInterface;
    setShipmentObject: (state: any) => void;
    setShipmentConfig: (state: any) => void;
}

export interface shipmentConfigInterface {
    resource: string;
    archiveResource: string;
    delimiter: string;
    platform: string;
    identifierType: string;
}

export interface shipmentIntegrationInterface extends integrationInterface {
    config: {
        resource: string;
        archiveResource: string;
        delimiter: string;
        platform: string;
        identifierType: string;
    };
}

export const ShipmentContext = React.createContext<ShipmentContext>({} as ShipmentContext);

export const ShipmentProvider: React.FC = ({ children }) => {
    const [shipmentConfig, setShipmentObject] = useState({} as shipmentConfigInterface);
    const [shipmentObject, setShipmentConfig] = useState({} as shipmentIntegrationInterface);

    return (
        <ShipmentContext.Provider
            value={{
                shipmentObject,
                shipmentConfig,
                setShipmentObject,
                setShipmentConfig,
            }}
        >
            {children}
        </ShipmentContext.Provider>
    );
};
