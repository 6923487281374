import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { contentWrapper } from './notFound.module.scss';

class NotFound extends React.Component {
    render() {
        return (
            <div className={contentWrapper}>
                <p> 404 page not found!</p>
                <Link to="/">
                    Sorry, click here to go back!
                </Link>
            </div>
        );
    }
}

export default NotFound;
