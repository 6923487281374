import React, { useContext, useEffect } from 'react';
import Shipment from '../../../components/integrations/viewEditIntegrationsTypes/shipment/shipment';
import { IntegrationServiceContext } from '../../../components/integrations/integrationService/integrationServiceContext';

export const ShipmentPage: React.FC = () => {
    const supplierName = localStorage.getItem('supplierName' as string);
    const { integration, getIntegration } = useContext(IntegrationServiceContext);

    useEffect(() => {
        if (Object.keys(integration).length === 0) {
            getIntegration(`${supplierName}`, 'fetch.shipment');
        }
    }, [integration]);

    return (
        <>
            <Shipment />
        </>
    );
};
