import React, { useContext, useEffect, useState } from 'react';
import {
    supplierInfoWrapper,
    infoHorizontalTemp,
    supplierInfoLeft,
    supplierInfoContent,
    infoWrapper,
    emailLabel,
    infoData,
    label,
    addressWrapper,
    infoHorizontal,
    addressHorizontal,
    addressLabels,
    infoLabels,
    data,
    content,
    contractData,
    activeContractWrapper,
    activeContractLabels,
    activeContractHorizontal,
} from './supplierInfo.module.scss';
import { ellaSupplierInfoInterface, supplierContactInterface, supplierContractInterface, supplierInfoInterface } from '../supplier/supplierInterfaces';
import { SupplierContext } from '../supplier/supplierContext';

const SupplierInfo: React.FC = () => {
    const { activeContract, supplierInfo } = useContext(SupplierContext);
    const info: ellaSupplierInfoInterface = JSON.parse(sessionStorage.getItem('supplierInfo') as string);
    let supplier: ellaSupplierInfoInterface = {} as ellaSupplierInfoInterface;

    if (!supplierInfo) {
        supplier = info;
    } else {
        supplier = supplierInfo;
    }

    const formatDate = (dateString: string): string => {
        let date = dateString.slice(0, -7);
        if (supplierInfo.created.timezone === 'Europe/Amsterdam') {
            date = `${date} CET`;
        }
        return date;
    };

    return (
        <>
            {Object.keys(supplierInfo).length === 0 ? (
                ''
            ) : (
                <div className={supplierInfoWrapper}>
                    <div className={supplierInfoContent}>
                        <div className={supplierInfoLeft}>
                            {/* Info */}
                            <div className={infoWrapper}>
                                <h5>Supplier information</h5>
                                <div className={content}>
                                    <div className={infoLabels}>
                                        <div className={label}>Number</div>
                                        <hr className={infoHorizontal} />
                                        <div className={label}>Name</div>
                                        <hr className={infoHorizontal} />
                                        <div className={label}>Invoice language</div>
                                        <hr className={infoHorizontal} />
                                        <div className={label}>SKU prefix</div>
                                        <hr className={infoHorizontal} />
                                        <div className={label}>Created at</div>
                                        <hr className={infoHorizontal} />
                                        <div className={label}>Last updated</div>
                                        <hr className={infoHorizontal} />
                                        <div className={emailLabel}>Invoice email addresses</div>
                                    </div>
                                    <div className={data}>
                                        <div className={infoData}>{supplier.supplierNumber}</div>
                                        <div className={infoData}>{supplier.invoicing.invoiceAddressName}</div>
                                        <div className={infoData}>{supplier.invoicing.invoiceLanguage}</div>
                                        <div className={infoData}>{supplier.skuPrefix}</div>
                                        <div className={infoData}>{formatDate(supplierInfo.created.date)}</div>
                                        <div className={infoData}>{formatDate(supplierInfo.updated.date)}</div>
                                        {supplier.invoicing.invoiceEmailAddresses.map((email, i) => {
                                            return (
                                                <>
                                                    <div className={infoData}>{email}</div>
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>

                            {/* address */}
                            <div className={addressWrapper}>
                                <h5>Supplier address</h5>
                                <div className={content}>
                                    <div className={addressLabels}>
                                        <div className={label}>Country code</div>
                                        <hr className={addressHorizontal} />
                                        <div className={label}>Address line 1</div>
                                        <hr className={addressHorizontal} />
                                        <div className={label}>Address line 2</div>
                                        <hr className={addressHorizontal} />
                                        <div className={label}>IBAN</div>
                                        <hr className={addressHorizontal} />
                                        <div className={label}>VAT number</div>
                                    </div>
                                    <div className={data}>
                                        <div className={infoData}>{supplier.invoicing.invoiceAddressCountryCode}</div>
                                        <div className={infoData}>{supplier.invoicing.invoiceAddressLine1}</div>
                                        <div className={infoData}>{supplier.invoicing.invoiceAddressLine2}</div>
                                        <div className={infoData}>{supplier.invoicing.iban}</div>
                                        <div className={infoData}>{supplier.invoicing.vatNumber}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* active contract */}
                        <div className={activeContractWrapper}>
                            <h5>Active Contract</h5>
                            <div className={content}>
                                <div className={activeContractLabels}>
                                    <div className={label}>ID</div>
                                    <hr className={activeContractHorizontal} />
                                    <div className={label}>Supplier Id</div>
                                    <hr className={activeContractHorizontal} />
                                    <div className={label}>Active</div>
                                    <hr className={activeContractHorizontal} />
                                    <div className={label}>Model</div>
                                    <hr className={activeContractHorizontal} />
                                    <div className={label}>Fee percentage</div>
                                    <hr className={activeContractHorizontal} />
                                    <div className={label}>Begin date</div>
                                    <hr className={activeContractHorizontal} />
                                    <div className={label}>Return policy</div>
                                    <hr className={activeContractHorizontal} />
                                    <div className={label}>Small business scheme</div>
                                    <hr className={activeContractHorizontal} />
                                    <div className={label}>Refund fee for returns</div>
                                    <hr className={activeContractHorizontal} />
                                    <div className={label}>End date</div>
                                    <hr className={activeContractHorizontal} />
                                    <div className={label}>Model</div>
                                    <hr className={activeContractHorizontal} />
                                    <div className={label}>Fee percentage</div>
                                </div>
                                <div className={data}>
                                    <div className={contractData}>{activeContract.id}</div>
                                    <div className={contractData}>{activeContract.supplierNumber}</div>
                                    <div className={contractData}>{activeContract.current ? 'Yes' : 'No'}</div>
                                    <div className={contractData}>{activeContract.model}</div>
                                    <div className={contractData}>{activeContract.feePercentage}</div>
                                    <div className={contractData}>hello</div>
                                    <div className={contractData}>{activeContract.returnPolicy}</div>
                                    <div className={contractData}>{activeContract.smallBusinessScheme ? 'Yes' : 'No'}</div>
                                    <div className={contractData}>{activeContract.refundFeeForReturns ? 'Yes' : 'No'}</div>
                                    <div className={contractData}>{activeContract.skontoDiscountPercentage ? activeContract.skontoDiscountPercentage : '-'}</div>
                                    <div className={contractData}>{activeContract.skontoPaymentDays ? activeContract.skontoPaymentDays : '-'}</div>
                                    <div className={contractData}>{activeContract.sellingPriceReimbursementPercentage ? activeContract.sellingPriceReimbursementPercentage : '-'}</div>
                                </div>
                            </div>
                        </div>

                        {/*/!* External contacts *!/*/}
                        {/*<div className={externalContactsWrapper}>*/}
                        {/*    <h5>External contacts</h5>*/}

                        {/*    {supplier.externalContacts.map((contact: supplierContactInterface, i: number) => {*/}
                        {/*        return (*/}
                        {/*            <>*/}
                        {/*                <div className={contactWrapper}>*/}
                        {/*                    <div key={i} className={content}>*/}
                        {/*                        <div className={contactsLabels}>*/}
                        {/*                            <div className={contactLabel}>Firstname</div>*/}
                        {/*                            <hr className={contactHorizontal} />*/}
                        {/*                            <div className={contactLabel}>LastName</div>*/}
                        {/*                            <hr className={contactHorizontal} />*/}
                        {/*                            <div className={contactLabel}>Email Address</div>*/}
                        {/*                            <hr className={contactHorizontal} />*/}
                        {/*                            <div className={contactLabel}>Phone number</div>*/}
                        {/*                            <hr className={contactHorizontal} />*/}
                        {/*                            <div className={contactLabel}>Department</div>*/}
                        {/*                        </div>*/}
                        {/*                        <div className={data}>*/}
                        {/*                            <div className={infoData}>{contact.firstName}</div>*/}
                        {/*                            <div className={infoData}>{contact.lastName}</div>*/}
                        {/*                            <div className={infoData}>{contact.emailAddress}</div>*/}
                        {/*                            <div className={infoData}>{contact.phoneNumber}</div>*/}
                        {/*                            <div className={infoData}>{contact.department}</div>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </>*/}
                        {/*        );*/}
                        {/*    })}*/}
                        {/*</div>*/}

                        {/*/!* Internal contacts *!/*/}
                        {/*<div className={internalContactsWrapper}>*/}
                        {/*    <h5>NADUVI contacts</h5>*/}

                        {/*    {supplier.externalContacts.map((contact: supplierContactInterface, i: number) => {*/}
                        {/*        return (*/}
                        {/*            <>*/}
                        {/*                <div className={contactWrapper}>*/}
                        {/*                    <div key={i} className={content}>*/}
                        {/*                        <div className={contactsLabels}>*/}
                        {/*                            <div className={contactLabel}>Firstname</div>*/}
                        {/*                            <hr className={contactHorizontal} />*/}
                        {/*                            <div className={contactLabel}>LastName</div>*/}
                        {/*                            <hr className={contactHorizontal} />*/}
                        {/*                            <div className={contactLabel}>Email Address</div>*/}
                        {/*                            <hr className={contactHorizontal} />*/}
                        {/*                            <div className={contactLabel}>Phone number</div>*/}
                        {/*                            <hr className={contactHorizontal} />*/}
                        {/*                            <div className={contactLabel}>Department</div>*/}
                        {/*                        </div>*/}
                        {/*                        <div className={data}>*/}
                        {/*                            <div className={infoData}>{contact.firstName}</div>*/}
                        {/*                            <div className={infoData}>{contact.lastName}</div>*/}
                        {/*                            <div className={infoData}>{contact.emailAddress}</div>*/}
                        {/*                            <div className={infoData}>{contact.phoneNumber}</div>*/}
                        {/*                            <div className={infoData}>{contact.department}</div>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </>*/}
                        {/*        );*/}
                        {/*    })}*/}
                        {/*</div>*/}
                    </div>
                </div>
            )}
        </>
    );
};

export default SupplierInfo;
