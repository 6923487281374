import React from 'react';
import NavBar from 'frontend-core/src/components/NavBar/NavBar';

export const Header: React.FC = () => {
    return (
        <>
            <NavBar
                appTitle={'Operations Portal'}
                homePath={'/overview'}
                items={[
                    { key: 'Suppliers', path: '/overview' },
                    { key: 'Integrations', path: '/supplier/integrations' },
                    { key: 'About', path: '/about' },
                ]}
            />
        </>
    );
};

export default Header;
